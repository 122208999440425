import React from 'react';
import { navigate } from '@reach/router';

import './menu.css';
import ActiveItem from './active-item';

export default function Menu({ inverse = false, onNavigate = () => {} }) {
  function handleActiveItemClick(href, e) {
    navigate(href);
    onNavigate();
  }

  function handleLinkClick(e) {
    e.preventDefault();
    const { href } = e.target;
    navigate(href);
    onNavigate();
  }

  let menuClassName = 'menu';
  if (inverse) {
    menuClassName += ' menu_inverse';
  }

  return (
    <ul className={menuClassName}>
      <li className="menu__item">
        <a
          className="menu__link"
          href="/#how-it-works"
          onClick={handleLinkClick}
        >
          So funktioniert's
        </a>
      </li>
      <ActiveItem
        href="/about-us"
        label="Über Uns"
        onLinkClick={handleActiveItemClick}
      />
      <ActiveItem
        href="/marketplace"
        label="Marktplatz"
        onLinkClick={handleActiveItemClick}
      />
      <ActiveItem
        href="/valuation"
        label="Bewertung"
        onLinkClick={handleActiveItemClick}
      />
    </ul>
  );
}
