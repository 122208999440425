import React from 'react';

import down from './down.svg';

/**
 * Custom select component.
 */
export default function Select({ children, fullSize = false, ...props }) {
  let groupClassName = 'hdlr1-form__group';
  if (fullSize) {
    groupClassName += ' hdlr1-form__group_full-size'
  }

  return (
    <div className={groupClassName}>
      <div className="hdlr1-form__control">
        <select className="hdlr1-form__control-input" {...props}>
          { children }
        </select>
        <img
          className="hdlr1-form__select-icon"
          src={down}
          alt="Down"
        />
      </div>
    </div>
  );
}
