import React from 'react';

import styles from './sidebar.module.css';
import MainMenu from './menu/main';
import ToolsMenu from './menu/tools';


export default function Sidebar() {
  return (
    <nav className={styles.sidebar}>
      <div style={{ marginBottom: '1rem' }}>
        <button
          className="dshb-button dshb-button_outline"
          style={{ width: '100%' }}
        >
          + Maschine bewerten
        </button>
      </div>
      <div style={{ marginBottom: '1.5rem' }}>
        <button
          className="dshb-button"
          style={{ width: '100%' }}
        >
          + Neue Suchanfrage
        </button>
      </div>

      <div className={styles.separator}></div>

      <div style={{ padding: '0 1rem' }}>
        <div style={{ marginBottom: '1rem' }}>
          <MainMenu />
        </div>

        <div style={{ marginBottom: '4rem' }}>
          <div className={styles.coming_soon_bold}>
            Coming soon
          </div>
          <div style={{ fontWeight: 300, lineHeight: 1.25 }}>
            (These functions are <br />
            temporarily unavailable)
          </div>
        </div>

        <div>
          <ToolsMenu />
        </div>
      </div>
    </nav>
  );
}
