import React from 'react';
import { Modal } from 'antd';

import './video-modal.css';
import './youtube-video.css';
import close from './close.svg';

export default function VideoModal({ visible, onClose }) {
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      className="video-modal"
      bodyStyle={{ padding: 0 }}
      maskClosable={true}
      centered={true}
      title="So funktioniert's Händler1"
      footer={null}
      width={null}
      closeIcon={ <img src={close} alt="Close" /> }
    >
      <div className="youtube-video">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/G7nagSVe_h4"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Händler1 Video"
        ></iframe>
      </div>
    </Modal>
  );
}
