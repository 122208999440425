import React from 'react';
import { Collapse } from 'antd';

import './marketplace-panel.css';
import Filters from '../filters';
import Input from '../../../components/input';
import search from './search.svg';
import down from './down.svg';
import up from './up.svg';

export default function Panel({ onFilterMouseDown }) {
  const CollapsePanel = Collapse.Panel;

  function handleSearchMouseDown(e) {
    e.preventDefault();
    onFilterMouseDown();
  }

  return (
    <div className="marketplace-panel">
      <div className="d-xl-none" style={{ marginBottom: '2rem' }}>
        <Input
          prefix={<img src={search} alt="Search" />}
          placeholder="Suchbegriff eingeben"
          fullSize
          onMouseDown={handleSearchMouseDown}
        />
      </div>
      <div className="d-none d-xl-block" style={{ marginBottom: '2rem' }}>
        <Input
          prefix={<img src={search} alt="Search" />}
          placeholder="Suchbegriff eingeben"
          fullSize
          onMouseDown={handleSearchMouseDown}
          controlStyle={{ paddingLeft: '.5rem' }}
          prefixStyle={{ marginRight: '.35rem' }}
        />
      </div>

      <div className="marketplace-panel__separator"></div>

      <div className="d-lg-none">
        <Collapse
          bordered={false}
          expandIconPosition="right"
          expandIcon={({ isActive }) => isActive ?
            <img src={up} alt="Up" /> :
            <img src={down} alt="Down" />
          }
          style={{ backgroundColor: 'white' }}
        >
          <CollapsePanel
            header={(
              <div className="marketplace-panel__collapse-header">Filter</div>
            )}
            style={{ borderBottomColor: '#efefef' }}
          >
            <Filters onControlMouseDown={onFilterMouseDown} />
          </CollapsePanel>
        </Collapse>
      </div>

      <div style={{ marginBottom: '1rem' }}></div>

      <div className="d-none d-lg-block">
        <Filters onControlMouseDown={onFilterMouseDown} />
      </div>
    </div>
  );
}
