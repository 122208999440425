const apiKey = process.env.REACT_APP_CONTACT_API_KEY || 
  'BDA1D693D38A86C69F41357683FF3';  // HARDCODE

const localApi = process.env.REACT_APP_API_URL || 
  'http://localhost:5000/dev';

const deployedApi = process.env.REACT_APP_API_URL ||
  'https://fich10ugy3.execute-api.eu-central-1.amazonaws.com/dev';

const apiUrl = (process.env.NODE_ENV !== 'production') ?
  localApi :
  deployedApi;


/**
 * Sends HTTP requests to a server endpoint.
 * @param {*} endpoint 
 * @param {*} method 
 * @param {*} body
 * @throws {Error} on request failure
 */
export default async function callApi(endpoint, method = 'get', body) {
  let res = await fetch(`${apiUrl}${endpoint}`, {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
    },
    method,
    body: JSON.stringify(body)
  });
  if (!res.ok) {
    throw new Error(`${res.status} ${res.statusText}`);
  }
  return await res.json();
}
