import React from 'react';
import { Link } from '@reach/router';

import './footer.css';
import envelope from './envelope-2.svg';
import call from './call.svg';
import pinMap from './pin-map.svg';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div style={{ marginBottom: '1.5rem' }}>
          <span className="footer__text footer__text_light">
            Gibt es Fragen?
          </span>
          {' '}
          <span className="footer__text footer__text_medium">
            Kontaktieren Sie uns wie und wann Sie möchten!
          </span>
        </div>

        <div className="footer__separator"></div>

        <div className="row">
          <div className="col-6 col-md-4" style={{ marginBottom: '2rem' }}>
            <div className="footer__heading">Über Uns</div>

            <div className="footer__link-wrapper">
              <Link to="/about-us" className="footer__link">Über Uns</Link>
            </div>
            <div className="footer__link-wrapper">
              <Link to="/terms-of-service" className="footer__link">AGB</Link>
            </div>
            <div className="footer__link-wrapper">
              <Link to="/privacy-policy" className="footer__link">Datenschutz</Link>
            </div>
            <div className="footer__link-wrapper">
              <Link to="/#contact" className="footer__link">Kontakt</Link>
            </div>
            <div className="footer__link-wrapper">
              <Link to="/imprint" className="footer__link">Impressum</Link>
            </div>
          </div>

          <div className="col-6 col-md-4">
            <div className="footer__heading">Konto</div>

            <div className="footer__link-wrapper">
              <Link
                to="/login"
                className="footer__link"
              >Login</Link>
            </div>
            <div className="footer__link-wrapper">
              <Link
                to="/sign-up"
                className="footer__link"
              >Anmelden</Link>
            </div>
            <div className="footer__link-wrapper">
              <Link
                to="/sign-up"
                className="footer__link"
              >Passwort vergessen</Link>
            </div>
          </div>

          <div className="col-12 col-md-4" style={{ marginBottom: '2.5rem' }}>
            <div className="footer__heading">Rheinlandmaschinen UG</div>

            <div className="footer__contact">
              <div className="footer__contact-icon-wrapper">
                <img
                  className="footer__contact-icon"
                  src={pinMap}
                  alt="Location"
                />
              </div>
              <span className="footer__contact-text">
                Scheibenstraße 49 <br />40476 Düsseldorf <br />Deutschland
              </span>
            </div>

            <div className="footer__contact">
              <div className="footer__contact-icon-wrapper">
                <img
                  className="footer__contact-icon"
                  src={envelope}
                  alt="Email"
                />
              </div>
              <a
                href="mailto:info@haendler1.de"
                className="footer__contact-text"
              >
                info@haendler1.de
              </a>
            </div>

            <div className="footer__contact">
              <div className="footer__contact-icon-wrapper">
                <img
                  className="footer__contact-icon"
                  src={call}
                  alt="Phone"
                />
              </div>
              <span className="footer__contact-text">
                0151 112 075 39
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
