import React from 'react';
import { Drawer } from 'antd';
import { navigate } from '@reach/router';

import './custom-drawer.css';
import Menu from '../menu';
import close from './close.svg';
import logo from './logo-inverse.png';

/**
 * Navbar drawer component.
 */
export default function CustomDrawer({
  visible, onClose, onLoginClick, onRegisterClick,
}) {
  function handleLinkClick(e) {
    e.preventDefault();
    const { href } = e.target;
    navigate(href);
    onClose();
  }

  return (
    <Drawer
      placement="right"
      visible={visible}
      onClose={onClose}
      width="auto"
      headerStyle={{ display: 'none' }}
      bodyStyle={{ paddingBottom: 0 }}
    >
      <div className="custom-drawer__content">
        <div style={{ flex: 1, paddingBottom: '1rem' }}>
          <button className="custom-drawer__close-button" onClick={onClose}>
            <img src={close} alt="Close" />
          </button>
        </div>
        
        <div className="custom-drawer__separator"></div>

        <div style={{ paddingTop: '.25rem', paddingBottom: '1.25rem' }}>
          <Menu
            inverse
            onNavigate={onClose}
          />
        </div>

        <div className="custom-drawer__separator"></div>

        <div style={{ padding: '1.5rem 0' }}>
          <div style={{ marginBottom: '1rem' }}>
            <button
              className="hdlr1-button hdlr1-button_outline"
              onClick={onLoginClick}
            >
              Einloggen
            </button>
          </div>
          <div>
            <button
              className="hdlr1-button"
              onClick={onRegisterClick}
            >
              <span className="d-none d-sm-inline">Jetzt Kostenlos</span>
              {' '}
              <span>registrieren</span>
            </button>
          </div>
        </div>

        <div className="custom-drawer__separator"></div>

        <div>
          <a
            className="custom-drawer__logo-link"
            href="/"
            onClick={handleLinkClick}
          >
            <img
              className="custom-drawer__logo-image"
              src={logo}
              alt="Logo"
            />
          </a>
        </div>
      </div>
    </Drawer>
  );
}
