import React, { useState } from 'react';

import styles from './results.module.css';
import PriceBlock from '../price-block';
import Plot from '../plot';
import Counter from '../counter';
import OperatorModal from '../operator/modal';
import phone from './phone-white.svg';
import envelope from './envelope-white.svg';

/**
 * Results success view component.
 */
export default function ResultsSuccessView({ machineData, predictionData }) {
  const [modalOpen, setModalOpen] = useState(false);

  const { lower, upper } = predictionData;
  const lowerTh = lower / 1000;
  const realLower = Math.round(lowerTh * 0.95) * 1000;
  const realUpper = Math.round(lowerTh * 1.05) * 1000;

  const { brand, model, year, hours, power } = machineData;

  const subject = 'Händler1.de - Maschine Bewertung';
  const body = 'Hallo,' +
    '\nich habe gerade diese Maschine mit Händler1.de bewertet:' +
    '\n\nHersteller: ' + brand +
    '\nModell: ' + model +
    '\nBaujahr: ' + year +
    '\nLeistung: ' + power + ' PS' +
    '\nBetriebsstunden: ' + hours +
    '\n\nDer geschätzte Abverkaufspreis liegt bei:' +
    `\n${lower} € - ${upper} €`;
  const mailtoLink = `mailto:?subject=${subject}&body=${encodeURIComponent(body)}`;

  return (
    <div>
      <div className={styles.price_section}>
        <div className={styles.price_blocks}>
          <PriceBlock
            color="#4d7aa8"
            title="Durchschnittlicher Angebotspreis:"
            hint="(Blauer Graph)"
            min={lower}
            max={upper}
          />
          <div className={styles.separator_1}></div>
          <PriceBlock
            color="#c71e1e"
            title="Realistischer Abverkaufspreis:"
            hint="(Roter Graph)"
            min={realLower}
            max={realUpper}
          />
        </div>
        <div className={styles.separator_2}></div>
      </div>

      <div className={styles.plot_section}>
        <Plot />
      </div>

      <div className={styles.counter_section} style={{ display: 'none' }}>
        <div style={{ marginBottom: '1rem' }}>
          <Counter label="Betriebsstunden (min):" />
        </div>
        <Counter label="Betriebsstunden (max):" />
      </div>

      <div className={styles.buttons_section}>
        <a
          href={mailtoLink}
          className="dshb-button"
          style={{ width: '12.5rem', marginRight: '1rem', marginBottom: '1rem' }}
        >
          <img src={envelope} alt="Mail" />
          <span style={{ marginLeft: '.5rem' }}>Bewertung teilen</span>
        </a>

        <button
          className="dshb-button"
          style={{ width: '12.5rem' }}
          onClick={ e => setModalOpen(true) }
        >
          <img src={phone} alt="Phone" />
          <span style={{ marginLeft: '.5rem' }}>Profi-Bewertung</span>
        </button>
      </div>

      <div style={{ clear: 'both' }}></div>

      <OperatorModal
        visible={modalOpen}
        onCancel={ () => setModalOpen(false) }
      />
    </div>
  );
}
