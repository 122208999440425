import React from 'react';

import './about-info.css';


export default function Info() {
  return (
    <div className="about-info container">
      <h1 className="about-info__heading">
        Von Händlern, für Händler!
      </h1>

      <p className="about-info__paragraph">
        Die Plattform Händler1.de wurde in Zusammenarbeit mit verschiedenen Landmaschinenhändlern konzipiert und entwickelt. 
        Unser Team besteht aus Verkaufsberatern und ehemaligen Mechanikern für Land- &amp; Baumaschinen.
        Die Bewertung von Landmaschinen ist oftmals sehr zeitintensiv und aufwendig.
        Die finalen Ankaufspreise beruhen meist auf Erfahrung und Bauchgefühl der Verkaufsberater, 
        daher ist das Risiko eines zu hohen Ankaufspreises und damit verbundene Verluste beim anschließenden Verkauf sehr hoch.
      </p>

      <p className="about-info__paragraph">
        Um hier mehr Sicherheit bieten zu können, haben wir von Händler1.de uns zum Ziel gesetzt,
        Landmaschinenhändler bei der korrekten Preisfindung zu unterstützen und die Maschinen zuverlässig zu bewerten!
        Mit unserem datenbasierten Bewertungstool erhalten Sie schnell und einfach eine Marktpreisanalyse in Abhängigkeit 
        von Betriebsstunden und zugleich eine realistische Aussage zu Verkaufspreisen Ihrer Maschine.
      </p>

      <p className="about-info__paragraph">
        Künftig werden wir weitere Services und Funktionen auf unserer Händlerplattform anbieten,
        um Sie bestmöglich für den Gebrauchtmaschinenhandel der Zukunft vorzubereiten!
        Haben Sie Fragen? Rufen Sie uns einfach an oder schreiben Sie uns eine E-Mail.
      </p>

      <p className="about-info__paragraph">
        Wir sind gerne für Sie da!
      </p>
    </div>
  );
}
