import React, { useState } from 'react';

import SignUpForm from './form';
import ThankYou from './thank-you';
import callApi from '../../utils/call-api';


/**
 * Sign-up component wrapper.
 * Meant to be the sign-up modal content.
 */
export default function SignUp() {
  const [isFormSubmitted, setFormSubmitted] = useState(false);

  function handleFormSubmit(data) {
    setFormSubmitted(true);
    window.scrollTo(0, 0);

    // TODO: loading & error states
    callApi('/contact', 'post', data)
      .catch(err => {
        if (process.env.NODE_ENV === 'development') {
          console.error(err);
        }
      });
  }

  return (
    <div>
      {
        isFormSubmitted ?
          <ThankYou /> :
          <SignUpForm onFormSubmit={handleFormSubmit} />
      }
    </div>
  );
}
