import React from 'react';

/**
 * Custom Select component of the Dashboard sub-app.
 */
export default function Select({ children, ...props }) {
  return (
    <div className="dshb-select__wrapper">
      <select
        className="dshb-select"
        {...props}
      >
        { children }
      </select>
      <i className="dshb-select__icon fas fa-chevron-down"></i>
    </div>
  );
}
