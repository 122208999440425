import { connect } from 'react-redux';
import ResultsSuccessView from './view';

function stp(s) {
  return {
    machineData: s.evaluator.machineData,
    predictionData: s.evaluator.predictionData,
  };
}

function dtp(d) {
  return {};
}

const ResultsSuccess = connect(
  stp,
  dtp,
)(ResultsSuccessView);

export default ResultsSuccess;
