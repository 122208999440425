import React from 'react';

import './about-banner.css';
import bg from './background.jpg';

export default function Banner() {
  return (
    <div className="about-banner">
      <img
        className="about-banner__image"
        src={bg}
        alt="Banner"
      />

      <div className="about-banner__overlay">
        <div className="container">
          <h2 className="about-banner__heading">
            Landmaschinen sind Unsere Leidenschaft
          </h2>
        </div>
      </div>
    </div>
  );
}
