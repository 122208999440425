import React from 'react';

import styles from './checkbox.module.css';

export default function Checkbox({ children, checked, ...props }) {
  return (
    <label className={styles.checkbox}>
      { children }
      <input
        type="checkbox"
        className={styles.checkbox_input}
        value=""
        {...props}
      />
      <span className={styles.checkbox_checkmark}></span>
    </label>
  );
}
