import React from 'react';

import styles from './toolbar.module.css';
import Back from './back';


export default function Toolbar() {
  return (
    <nav className={styles.toolbar}>
      <Back />
      <button className="dshb-button">
        Bewertungsanfragen
      </button>
    </nav>
  );
}
