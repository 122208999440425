import React, { useEffect, useRef } from 'react';
import fitty from 'fitty';

import './offer.css';

/**
 * Offer carousel item component.
 */
export default function Offer({ image, brand, model, description }) {
  const title = useRef(null);

  // fitting the title font-size to container width
  useEffect(() => {
    fitty(title.current, { minSize: 12, maxSize: 22 });
  }, []);

  return (
    <div className="offer">
      <div className="offer__image-wrapper">
        <img
          className="offer__image"
          src={image}
          alt={`${brand} ${model}`}
        />
      </div>
      <div className="offer__title">
        <span ref={title}>{ brand } { model }</span>
      </div>
      <div className="offer__description" style={{ display: 'none' }}>
        { description }
      </div>
      <div className="offer__price-range">
        <span>€</span>
        <span className="offer__price_muted"></span>
        <span> - </span>
        <span className="offer__price_muted"></span>
      </div>
    </div>
  );
}
