import React, { useState, useEffect } from 'react';

import './finish-step.css';
import '../styles/step-frame.css';
import '../styles/predictor.css';
import StepId from '../../data/step-id.enum';
import ErrorScreen from '../error-screen';
import LoadingScreen from '../loading-screen';

function FinishSuccess({ onRestart }) {
  return (
    <div className="step-frame">
      <div className="finish-step step-frame__content">
        <h1 className="predictor__heading-1">
          Vielen Dank!
        </h1>

        <p className="finish-step__message predictor__text predictor__text_large">
          Wir werden uns in Kürze mit Ihnen in Verbindung setzen.
        </p>

        <div className="finish-step__button-block">
          <a
            href="/"
            className="predictor__button predictor__button_accent"
          >
            Zur Startseite
          </a>
        </div>
      </div>
    </div>
  );
}

export default function FinishView({ content, onRestart }) {
  const [_isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  useEffect(() => {
    const finishRecord = content
      .find(x => x.stepId === StepId.FINISH);
    if (finishRecord) {
      const { isError } = finishRecord;
      if (isError) {
        setError(true);
      } else {
        setSuccess(true);
      }
    }
  }, [content]);

  function renderBody() {
    if (_isError) {
      return <ErrorScreen />;
    }
    if (isSuccess) {
      return <FinishSuccess onRestart={onRestart} />;
    }
    return <LoadingScreen />;
  }

  return renderBody();
}
