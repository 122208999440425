import alignCategoryWithType from '../helpers/align-category-with-type';

export default function makeGetBrands(categories, brands) {
  return function(values) {
    const { categoryId, machineTypeId } =
      alignCategoryWithType(categories, values);

    if (machineTypeId) {
      return brands
        .filter(x => !!x.modelGroups
          .find(y => y.machineTypeId === machineTypeId)
        );
    }

    return brands
      .filter(x => !!x.modelGroups
        .find(y => y.categoryId === categoryId)
      );
  }
}
