import React from 'react';

import './verify.css';
import Input from '../../components/input';
import lock from './lock.svg';

export default function Password({ incorrect = false, onAttempt }) {
  function handleSubmit(e) {
    e.preventDefault();
    const password = e.target.password.value;
    onAttempt(password);
  }

  function renderIncorrect() {
    return incorrect &&
      <div className="verify__incorrect">
        Das Passwort ist inkorrekt.
      </div>;
  }

  return (
    <div className="verify container">
      <h1 className="verify__heading">Zugang zum Bewertungstool</h1>

      <p className="verify__subheading">
        Bitte geben Sie das Passwort ein, dass Sie per E-Mail 
        von uns zugesendet bekommen haben.
      </p>

      <form onSubmit={handleSubmit}>
        <div className="verify__input-wrapper">
          <Input
            name="password"
            type="password"
            placeholder="Passwort"
            prefix={<img src={lock} alt="Lock" />}
            fullSize
            required
          />
          { renderIncorrect() }
        </div>

        <div className="verify__submit-wrapper">
          <button type="submit" className="hdlr1-button">
            Einloggen
          </button>
        </div>
      </form>

      <p className="verify__hint">
        <span className="verify__text-accent">Sie haben kein Passwort?</span> Schreiben 
        Sie uns jetzt eine E-Mail und informieren 
        Sie sich, wie man heutzutage Maschinen variantenspezifisch bewertet.
      </p>
    </div>
  );
}
