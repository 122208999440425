import React, { useRef } from 'react';
//import fitty from 'fitty';

import './machine-card.css';


/**
 * Marketplace machine card component.
 */
export default function MachineCard({
  brand, model, image, location, priceLower, priceUpper,
}) {
  const title = `${brand} ${model}`;
  const priceRef = useRef(null);

  // fitting the price font-size to container width
  /*useEffect(() => {
    fitty(priceRef.current, { minSize: 12, maxSize: 16 });
  }, []);*/

  function formatPrice(price) {
    return price.toLocaleString(
      'de-DE', 
      /*{ style: 'currency', currency: 'EUR' }*/
    );
  }

  return (
    <div className="machine-card">
      <div className="machine-card__media">
        <img
          className="machine-card__image"
          src={image}
          alt={title}
        />
        <button className="machine-card__like-button">
          <i className="machine-card__like-icon fas fa-heart"></i>
        </button>
      </div>

      <div className="machine-card__description">
        <div className="machine-card__left">
          <div className="machine-card__title">
            <span>{ title }</span>
          </div>
          <div className="machine-card__location">{ location }</div>
        </div>
        <div className="machine-card__right">
          <div className="machine-card__price" style={{ display: 'none' }}>
            <span ref={priceRef}>
              { formatPrice(priceLower) }€ - { formatPrice(priceUpper) }€
            </span>
          </div>
          {/* copying the markup from carousel offer */}
          <div
            className="offer__price-range"
            style={{ marginBottom: '0.1rem', lineHeight: 1 }}
          >
            <span className="offer__price_muted"></span>
            <span className="d-lg-none"> - </span>
            <span className="offer__price_muted d-lg-none"></span>
            <span>€</span>
          </div>
          <div className="machine-card__forecast">Preisprognose</div>
        </div>
      </div>
    </div>
  );
}
