import { call, put, take  } from 'redux-saga/effects';
import EvaluatorAction from './action-type.enum';

const plotUrl =
  'https://0hf2hqpt5i.execute-api.eu-central-1.amazonaws.com/Prod/plot';  // env
  //'http://localhost:3001/evaluation';   // env, dev

/**
 * Fetches evaluation data from backend.
 * @param {*} params 
 */
async function fetchEvaluation(params) {
  const { power, hours } = params;
  const body = Object.assign({}, params, {
    x_axis: "engine_hours",
    x_lower: 0,
    x_upper: hours * 2,
    PS: power,
    engine_hours: hours,
  });

  const res = await fetch(plotUrl, {
    method: 'post',
    body: JSON.stringify(body),
  });
  if (!res.ok) {
    throw new Error(`Evaluation fetch error: ${res.status} ${res.statusText}`);
  }
  return await res.json();
}

/**
 * Evaluator root saga.
 */
export default function* evaluatorSaga() {
  while (true) {
    const action = yield take(EvaluatorAction.FORM_SUBMIT);
    const params = action.payload.values;

    yield put({ type: EvaluatorAction.EVAL_REQUEST });

    try {
      const res = yield call(fetchEvaluation, params);
      yield put({
        type: EvaluatorAction.EVAL_SUCCESS,
        payload: {
          plotData: res.plot,
          predictionData: res.prediction,
        }
      });
    } catch(err) {
      yield put({ type: EvaluatorAction.EVAL_ERROR });
    }
  }
}
