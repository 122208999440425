import React from 'react';

import './list-select.css';
import '../styles/custom-scrollbar.css';

/**
 * REPEATING CODE (../list-select)
 */
function getActiveOptionId(stepId, values) {
  if (!values) {
    return null;
  }
  const valueRecord = values.find(x => x.stepId === stepId);
  if (!valueRecord) {
    return null;
  }
  const { value } = valueRecord;
  return value.id;
}

/**
 * REPEATING CODE (../number-select)
 */
function ListSelectOption({ option, activeId, onClick }) {
  let className = 'list-select__option';
  if (option.id === activeId) {
    className += ' list-select__option_active';
  }
  return (
    <li className={className} onClick={onClick}>
      { option.label }
    </li>
  );
}

export default function BaseListSelectView({ options, stepId, values, onSelect }) {
  function renderOptions() {
    const activeId = getActiveOptionId(stepId, values);
    return options.map((x, i) => (
      <ListSelectOption
        key={i}
        option={x}
        activeId={activeId}
        onClick={ e => onSelect(stepId, x) }
      />
    ));
  }

  return (
    <ul className="list-select__list custom-scrollbar">
      { renderOptions() }
    </ul>
  );
}
