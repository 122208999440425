import React from 'react';

import './terms-of-service.css';

export default function TermsOfService() {
  return (
    <div className="terms-of-service hdlr1-article container">
      <h1>Allgemeine Geschäfts&shy;bedingungen</h1>

      <h2>§ 1 Präambel</h2>

      <p>
        (1) Die Rheinlandmaschinen UG (nachfolgend „Diensteanbieter“ genannt) betreibt unter der Domain www.händler1.de eine Plattform, auf der Nutzer (nachfolgend „Verkäufer“ genannt) ihre Landmaschinen verkaufen können.
      </p>
      <p>
        (2) Der Diensteanbieter übernimmt bei der Vermarktung der jeweiligen Landmaschinen nur die Funktion eines Vermittlers.
      </p>
      <p>
        (3) In den späteren Abschluss eines Vertrags über den Verkauf von Landmaschinen zwischen Verkäufer und Dritten sowie dessen Abwicklung ist der Diensteanbieter nicht eingebunden.
      </p>
      <p>
        (4) Abweichende oder entgegenstehende Bedingungen werden von uns nicht anerkannt, sofern wir diesen nicht ausdrücklich zugestimmt haben.
      </p>
      <p>
        (5) Änderungen dieser Geschäftsbedingungen werden Ihnen schriftlich, per Telefax oder per E-Mail mitgeteilt. Widersprechen Sie einer Änderung nicht innerhalb von vier Wochen nach Zugang der Mitteilung, gelten die Änderungen als durch Sie anerkannt. Auf das Widerspruchsrecht und die Rechtsfolgen des Schweigens werden Sie im Falle der Änderung der Geschäftsbedingungen noch gesondert hingewiesen.
      </p>
      <p>
        (6) Die Allgemeinen Geschäftsbedingungen gelten gegenüber Unternehmern im Sinne von § 14 BGB, also natürlichen oder juristischen Personen oder einer rechtsfähigen Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt.
      </p>

      <h2>§ 2 Gegenstand der Allgemeinen Geschäftsbedingungen</h2>

      <p>
        (1) Der Diensteanbieter erbringt dem Verkäufer gegenüber Vermittlungsleistungen beim Verkauf von dem Verkäufer ausgewählten Landmaschinen.
      </p>
      <p>
        (2) Die Zusammenarbeit mit dem Diensteanbieter beginnt über die sog. „Vermittlungsanfrage“-Funktion, die der Verkäufer jederzeit über die Online-Plattform starten kann. Hierbei werden vom Diensteanbieter Informationen wie Maschinenart, Hersteller, Modell, Baujahr und Betriebsstunden abgefragt.
      </p>
      <p>
        (3) Nachdem der Verkäufer seine Kontaktdaten angegeben hat, wird der Verkäufer unverzüglich vom Diensteanbieter per E-Mail kontaktiert, um mit ihm die Einzelheiten wie Terminabsprache, Inspizierung der Landmaschine und die anschließende Vermarktung abzuklären. Der Diensteanbieter führt die Inspizierung der Landmaschine mit einer Audit-App durch. Die Landmaschinen werden auf den bekannten Online-Marktplätzen und auf der Plattform des Diensteanbieters zum Verkauf angeboten.
      </p>
      <p>
        (4) Der Diensteanbieter begleitet den Verkäufer auf dem kompletten Verkaufsweg und übernimmt die Bearbeitung der Kontaktanfragen, die Preisverhandlung, die Auftragsabwicklung sowie die Sicherstellung der Zahlung. Preisanpassungen und Kaufangebote werden stets mit dem Verkäufer abgesprochen, so dass dem Verkäufer immer die volle Kontrolle über die Vermarktung seiner Landmaschinen verbleibt.
      </p>

      <h2>§ 3 Pflichten des Diensteanbieters</h2>

      <p>
        (1) Der Diensteanbieter verpflichtet sich zu in § 2 (3) und (4) genannten Servicedienstleistungen unter ausschließlicher Verwendung der vom Verkäufer zur Verfügung gestellten Hyperlinks, Werbematerialien und/oder Informationen.
      </p>
      <p>
        (2) Der Diensteanbieter ist zu einer Veränderung der vom Verkäufer bereitgestellten Werbematerialien und/oder Informationen betreffend die zu veräußernden Landmaschinen nicht berechtigt. Es obliegt insoweit dem Verkäufer, dem Diensteanbieter alle nötigen Hyperlinks, Werbematerialien und Informationen zu der jeweiligen Landmaschine zur Verfügung zu stellen.
      </p>
      <p>
        (3) Am Verkauf der Landmaschine ist der Diensteanbieter für den Verkäufer tatsächlich beteiligt. Dies gilt insbesondere für die Inspizierung und die Online-Bewertung der jeweiligen Landmaschine, die Vermarktung und die Abwicklung des Verkaufs.
      </p>
      <p>
        Der Diensteanbieter ist zur Vertretung oder zum sonstigen rechtsgeschäftlichen Tätigwerden für den Verkäufer weder berechtigt noch verpflichtet.
      </p>
      <p>
        (4) Der Diensteanbieter gestaltet die Plattform sowie seine Dienstleistungen im Einklang mit deutschem und in Deutschland anwendbarem Recht. Es werden auf der Plattform keine rassistischen, pornographischen oder beleidigenden Inhalte verbreitet.
      </p>
      <p>
        (5) Der Diensteanbieter entwickelt seine Plattform nach eigenem Ermessen fortlaufend weiter und passt sie an die technische Entwicklung an.
      </p>

      <h2>§ 4 Provision</h2>

      <p>
        (1) Für den Fall eines wirksam geschlossenen Kaufvertrags zwischen dem Verkäufer und dem Käufer erheben wir eine Provision von 1,75 % des Verkaufspreises, mindestens aber eine Provision in Höhe von EUR 500,00 inklusive Umsatzsteuer.
      </p>
      <p>
        Dienstleistungen des Diensteanbieters bis zum Zeitpunkt des wirksam zustande gekommenen Kaufvertrags sind für den Verkäufer kostenlos.
      </p>
      <p>
        (2) Die Zahlung der Provision erfolgt gegen Rechnung. Mit Zugang der Rechnung wird die Provisionsforderung fällig und ist ohne Abzug zahlbar.
      </p>
      <p>
        (3) Der Verkäufer kommt ohne weitere Erklärungen von uns in Verzug, wenn er nicht innerhalb von 30 Tagen nach Fälligkeit und Zugang der Rechnung bezahlt hat.
      </p>
      <p>
        (4) Für den Fall, dass der Kunde bei Fälligkeit nicht leistet, sind die ausstehenden Beträge ab dem Tag der Fälligkeit mit 9% - Punkten über dem Basiszinssatz zu verzinsen; die Geltendmachung höherer Zinsen und weiterer Schäden im Falle des Verzugs bleibt unberührt.
      </p>
      <p>
        (5) Eine Aufrechnung durch den Verkäufer ist ausgeschlossen, es sei denn, die Gegenforderung des Verkäufers ist unbestritten oder rechtskräftig festgestellt.
      </p>

      <h2>§ 5 Kündigung</h2>

      <p>
        (1) Das Vertragsverhältnis kann von beiden Parteien jederzeit in Textform gekündigt werden.
      </p>
      <p>
        (2) Das Recht zur außerordentlichen Kündigung aus wichtigem Grund bleibt unberührt.
      </p>

      <h2>§ 6 Dienstangebot und Verfügbarkeit der Dienste</h2>

      <p>
        (1) Der Diensteanbieter stellt Ihnen auf der Plattform bestimmte Dienste zur Verfügung.
      </p>
      <p>
        Inhalt und Umfang der Dienste bestimmen sich nach den jeweils aktuell auf dem Portal verfügbaren Funktionalitäten.
      </p>
      <p>
        (2) Zu den auf der Plattform verfügbaren Inhalte gehören Dienste Dritter, zu welchen der Diensteanbieter lediglich den Zugang vermittelt. Für die Inanspruchnahme derartiger Dienste – die jeweils als Dienste Dritter kenntlich gemacht sind – können von diesen Webportal-Nutzungsbedingungen abweichende oder zusätzliche Regelungen gelten, auf die der Diensteanbieter Sie jeweils hinweisen wird.
      </p>
      <p>
        (3) Im Übrigen besteht ein Anspruch auf die Nutzung der auf der Plattform verfügbaren Inhalte nur im Rahmen der technischen und betrieblichen Möglichkeiten beim Diensteanbieter. Der Diensteanbieter bemüht sich um eine möglichst unterbrechungsfreie Nutzbarkeit seiner Plattform. Jedoch können durch technische Störungen (wie z.B. Unterbrechung der Stromversorgung, Hardware- und Softwarefehler, technische Probleme in den Datenleitungen) zeitweilige Beschränkungen oder Unterbrechungen auftreten.
      </p>

      <h2>§ 7 Schutz der Inhalte, Verantwortlichkeit für Inhalte Dritter</h2>

      <p>
        (1) Die auf der Plattform verfügbaren Inhalte sind überwiegend geschützt 
        durch das Urheberrecht oder durch sonstige Schutzrechte und <b>stehen jeweils 
        im Eigentum des Diensteanbieters oder sonstiger Dritter, welche die 
        jeweiligen Inhalte zur Verfügung gestellt haben</b>. Die Zusammenstellung der 
        Inhalte als solche ist gegebenenfalls geschützt als Datenbank oder 
        Datenbankwerk im Sinne der §§ 4 Absatz 2, 87a Absatz 1 UrhG. Sie dürfen 
        diese Inhalte lediglich gemäß diesen Webportal-Nutzungsbedingungen sowie 
        im auf der Plattform vorgegebenen Rahmen nutzen.
      </p>
      <p>
        (2) Die auf der Plattform verfügbaren Inhalte stammen teilweise vom 
        Diensteanbieter und teilweise von sonstigen Dritten. Inhalte sonstiger 
        Dritter werden nachfolgend zusammenfassend „<b>Drittinhalte</b>“ genannt. Der 
        Diensteanbieter führt bei Drittinhalten keine Prüfung auf Vollständigkeit, 
        Richtigkeit und Rechtmäßigkeit durch und <b>übernimmt daher keinerlei 
        Verantwortung oder Gewährleistung für die Vollständigkeit, Richtigkeit, 
        Rechtmäßigkeit und Aktualität der Drittinhalte</b>. Dies gilt auch im Hinblick 
        auf die Qualität der Drittinhalte und deren Eignung für einen bestimmten 
        Zweck, und auch, soweit es sich um Drittinhalte auf verlinkten externen 
        Webseiten handelt.
      </p>
      <p>
        (3) Der Diensteanbieter hat im Übrigen alle Informationen und Inhalte nach bestem Wissen und Gewissen zusammengestellt. Der Diensteanbieter haftet nicht für die Vollständigkeit, Richtigkeit und Aktualität der auf dieser Internetseite bereitgestellten Informationen.
      </p>

      <h2>§ 8 Datenschutz</h2>

      <p>
        (1) Zu den Qualitätsansprüchen des Diensteanbieters gehört es, 
        verantwortungsbewusst mit den persönlichen Daten der Verkäufer (diese 
        Daten werden nachfolgend „<b>personenbezogene Daten</b>“ genannt) umzugehen. 
        Die sich aus der Nutzung der verfügbaren Inhalte ergebenden 
        personenbezogenen Daten werden vom Diensteanbieter daher nur erhoben, 
        gespeichert und verarbeitet, soweit dies zur vertragsgemäßen 
        Leistungserbringung erforderlich und durch gesetzliche Vorschriften 
        erlaubt, oder vom Gesetzgeber angeordnet ist. Der Diensteanbieter wird 
        Ihre personenbezogenen Daten vertraulich sowie entsprechend den Bestimmungen 
        des geltenden Datenschutzrechts behandeln und grundsätzlich nicht an 
        Dritte weitergeben.
      </p>
      <p>
        (2) Hierüber hinaus verwendet der Diensteanbieter Ihre personenbezogenen Daten nur, soweit Sie hierzu ausdrücklich eingewilligt haben. Eine von Ihnen erteilte Einwilligung können Sie jederzeit widerrufen.
      </p>
      <p>
        (3) Im Übrigen verweisen wir auf unsere 
        Datenschutzerklärung <a href="/privacy-policy">hier</a>.
      </p>

      <h2>§ 9 Haftung</h2>

      <p>
        (1) Der Diensteanbieter haftet für Vorsatz und grobe Fahrlässigkeit uneingeschränkt, für leichte Fahrlässigkeit jedoch nur bei der Verletzung vertragswesentlicher Pflichten. Die Haftung bei Verletzung einer vertragswesentlichen Pflicht ist auf den vertragstypischen Schaden begrenzt, mit dessen Entstehen der Diensteanbieter bei Vertragsabschluss aufgrund der zu diesem Zeitpunkt bekannten Umstände rechnen musste.
      </p>
      <p>
        (2) Die vorstehenden Haftungsbeschränkungen gelten sinngemäß auch zugunsten der Erfüllungsgehilfen des Diensteanbieters.
      </p>
      <p>
        (3) Die vorstehende Haftungsbeschränkung gilt nicht im Falle von Arglist, im Falle von Körper- bzw. Personenschäden, für die Verletzung von Garantien sowie für Ansprüche aus Produkthaftung.
      </p>
      <p>
        (4) Soweit über die Plattform eine Möglichkeit der Weiterleitung von Datenbanken, Websites, Dienste etc. Dritter, z.B. durch die Einstellung von Links oder Hyperlinks gegeben ist, haftet der Diensteanbieter weder für Zugänglichkeit, Bestand oder Sicherheit dieser Datenbanken oder Dienste, noch für den Inhalt derselben. Insbesondere haftet der Diensteanbieter nicht für deren Rechtmäßigkeit, inhaltliche Richtigkeit, Vollständigkeit, Aktualität etc.
      </p>
      <p>
        (5) Der Diensteanbieter haftet den Verkäufern nicht für die Leistungen und Angebote der Dritten (Käufer). Ein Vertragsabschluss über den Erwerb von Landmaschinen kommt ausschließlich zwischen dem Verkäufer und dem Käufer zustande.
      </p>

      <h2>§ 10 Schlussbestimmungen</h2>

      <p>
        (1) Sollten einzelne Bestimmungen dieser Geschäftsbedingungen ganz oder teilweise unwirksam sein oder werden, wird hierdurch die Gültigkeit der übrigen Bestimmungen nicht berührt. In diesem Fall werden die Parteien eine neue Bestimmung vereinbaren, welche die Parteien bei sachgerechter Abwägung der beiderseitigen Interessen gewählt hätten, wenn ihnen die Unwirksamkeit der Bestimmung bewusst gewesen wäre. Bis dahin gilt das Gesetz. Dies gilt entsprechend für den Fall einer von den Parteien bei Vertragsschluss nicht vorhergesehenen Lücke im Vertrag. § 306 Absatz 3 BGB bleibt unberührt.
      </p>
      <p>
        (2) Es gilt deutsches Recht unter Ausschluss des UN-Kaufrechts und des Kollisionsrechts.
      </p>
      <p>
        (3) Ausschließlicher Gerichtsstand für alle sich aus diesen Geschäftsbedingungen ergebenden Streitigkeiten ist, soweit eine solche Gerichtsstandsvereinbarung zulässig ist, der Sitz des Diensteanbieters.
      </p>

      <div className="terms-of-service__separator"></div>

      <p>September 2019</p>
    </div>
  );
}
