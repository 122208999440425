import React from 'react';

import Banner from './banner';
import BenefitLine from './benefit-line';
import Info from './info';

export default function AboutUs() {
  return (
    <div>
      <Banner />
      <BenefitLine />
      <Info />
    </div>
  );
}
