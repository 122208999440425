import React from 'react';
import { Router } from '@reach/router';

import Homepage from './pages/homepage';
import AboutUs from './pages/about-us';
import Marketplace from './pages/marketplace';
import Valuation from './pages/valuation';
import Main from './components/main';
import Imprint from './pages/imprint';
import TermsOfService from './pages/terms-of-service';
import PrivacyPolicy from './pages/privacy-policy';
import SignUpPage from './pages/sign-up';
import Dashboard from './dashboard';
import NotFound from './pages/not-found';
import CookieBarWrapper from './components/cookie-bar/wrapper';
import Evaluator from './dashboard/evaluator';
import DashboardNext from './dashboard/next';
import EvaluatorNext from './dashboard/evaluator/next';
import Login from './pages/login';
import DashboardNotFound from './dashboard/not-found';
import Email1 from './email/email1';
import Email2 from './email/email2';


/**
 * The App component.
 */
function App() {
  function renderDevEvaluatorRoute() {
    if (process.env.NODE_ENV === 'development') {
      return <Evaluator path="/evaluator-dev" />;
    }
  }

  function renderEmail1() {
    if (process.env.NODE_ENV === 'development') {
      return <Email1 path="/email" />;
    }
  }
  function renderEmail2() {
    if (process.env.NODE_ENV === 'development') {
      return <Email2 path="/email2" />;
    }
  }

  return (
    <Router>
      <Main path="/">
        <CookieBarWrapper path="/">
          <Homepage path="/" />
          <AboutUs path="/about-us" />
          <Marketplace path="/marketplace" />
          <Valuation path="/valuation" />
          <Imprint path="/imprint" />
          <TermsOfService path="/terms-of-service" />
          <PrivacyPolicy path="/privacy-policy" />
          <SignUpPage path="/sign-up" />
          <Login path="/login" />
          <NotFound default />
        </CookieBarWrapper>
        <Dashboard path="/evaluator" />
        { renderDevEvaluatorRoute() }
      </Main>
      <DashboardNext path="/dashboard">
        <EvaluatorNext path="/" />
        <DashboardNotFound default />
      </DashboardNext>
      { renderEmail1() }
      { renderEmail2() }
    </Router>
  );
}

export default App;
