import React, { useState } from 'react';
import { Link, navigate } from '@reach/router';

import './navbar.css';
import CustomDrawer from './custom-drawer';
import Menu from './menu';
import Logo from './logo';


export default function Navbar() {
  const [isDrawerVisible, setDrawerVisible] = useState(false);

  function handleLoginClick(e) {
    setDrawerVisible(false);
    navigate('/login');
  }

  function handleRegisterClick(e) {
    setDrawerVisible(false);
    navigate('/sign-up');
  }

  return (
    <nav className="navbar">
      <Link className="navbar__brand" to="/">
        <Logo />
      </Link>

      <button
        className="navbar__menu-button"
        onClick={ e => setDrawerVisible(true) }
      >
        <i className="icon-menu navbar__menu-icon"></i>
      </button>

      <div className="navbar__expand">
        <Menu />

        <Link
          to="/login"
          className="hdlr1-button hdlr1-button_outline"
          style={{ marginLeft: '2.5rem' }}
        >
          Einloggen
        </Link>

        <Link
          to="/sign-up"
          className="navbar__signup-button hdlr1-button"
        >
          <span className="navbar__signup-button-expand">Jetzt Kostenlos</span>
          {' '}
          <span>registrieren</span>
        </Link>
      </div>

      <CustomDrawer
        visible={isDrawerVisible}
        onClose={ () => setDrawerVisible(false) }
        onLoginClick={handleLoginClick}
        onRegisterClick={handleRegisterClick}
      />
    </nav>
  );
}
