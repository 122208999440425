import React from 'react';
import { Location } from '@reach/router';

export default function ActiveItem({ href, label, onLinkClick }) {
  function handleClick(e) {
    e.preventDefault();
    onLinkClick(href, e);
  }

  return (
    <Location>
      {
        function({ location }) {
          let itemClassName = 'menu__item';
          if (location.pathname === href) {
            itemClassName += ' menu__item_active';
          }
          return (
            <li className={itemClassName}>
              <a className="menu__link" href={href} onClick={handleClick}>
                { label }
              </a>
            </li>
          );
        }
      }
    </Location>
  );
}
