import React from 'react';

import '../forecast-content.css';
import '../../styles/predictor.css';
import ForecastAutomatic from '../automatic';
import ForecastManual from '../manual';

function AutomaticHints() {
  return (
    <div className="forecast-content__hint-block container">
      <p className="forecast-content__paragraph" style={{ margin: 0 }}>
        <span className="forecast-content__text-warning">Wichtig</span>: 
        Die von uns ermittelten Preise sind abhängig von den zuvor 
        gemachten Angaben. Es handelt sich daher lediglich um eine 
        unverbindliche Empfehlung. Je nach Marktsituation und tatsächlichem 
        Zustand der Maschine, kann ein möglicher Erlös abweichen.
      </p>
    </div>
  );
}

function ManualHints() {
  return (
    <div className="forecast-content__hint-block container">
      <p className="forecast-content__paragraph">
        <span className="forecast-content__text-warning">Wichtig</span>: 
        Wir arbeiten an einer automatisierten Prognose, 
        bis dahin nehmen wir jede Prüfung manuell vor.
      </p>

      <p className="forecast-content__paragraph" style={{ margin: 0 }}>
        <span className="forecast-content__text-warning">Hinweis</span>: 
        Zukünftig können Sie bei Hektor.One Ihre Bewertungen speichern, 
        andere Händler aus dem Hektor.One Netzwerk nach Ihrer Einschätzung 
        fragen und eine 12 Monatsprognose zum Preisverfall einsehen. Gerne 
        halten wir Sie über den aktuellen Entwicklungsstand auf dem 
        Laufenden. Registrieren Sie sich hier für unseren Newsletter.
      </p>
    </div>
  );
}

export default function ForecastContent(props) {
  const { priceFrom, priceTill } = props;
  const isAutomatic = priceFrom && priceTill;

  function renderMainContent() {
    if (isAutomatic) {
      return <ForecastAutomatic {...props} />;
    } else {
      return <ForecastManual {...props} />
    }
  }

  function renderHints() {
    return isAutomatic ?
      <AutomaticHints /> :
      <ManualHints />;
  }

  return (
    <div data-testid="forecast-content" className="forecast-content">
      <div className="forecast-content__main container">
        { renderMainContent() }
      </div>

      <div style={{ marginTop: '1rem' }}>
        { renderHints() }
      </div>
    </div>
  );
}
