import React, { useState, useEffect } from 'react';
import { navigate, useLocation } from '@reach/router';

import styles from './dashboard.module.css';
import Sidebar from './components/sidebar';
import Toolbar from './components/toolbar';
import Navbar from './components/navbar';
import CustomDrawer from './components/custom-drawer';
import SidebarHeader from './components/sidebar/header';


/**
 * Next version of the dashboard.
 */
export default function DashboardNext({ children }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  
  // scrolling to top on route change
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [pathname]);

  // cookie-based auth
  const cookieName = '_hdlr1';  // also in Tools Menu and Login
  const cookieParts = document.cookie.split(';');
  const token = cookieParts.find(x => {
    const name = x.split('=')[0];
    return name.trim() === cookieName;
  });
  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token]);

  // body
  function renderBody() {
    return (
      <div>
        <div className={styles.navbar_wrapper}>
          <Navbar onMenuClick={ () => setDrawerOpen(true) } />
        </div>
        <div className={styles.layout}>
          <div className={styles.left}>
            <SidebarHeader />
            <Sidebar />
          </div>
          <div className={styles.right}>
            <div className={styles.right_container}>
              <div style={{ display: 'none' }}>
                <Toolbar />
              </div>
              <main className={styles.main}>
                { children }
              </main>
            </div>
          </div>
        </div>

        <CustomDrawer
          visible={drawerOpen}
          onClose={ () => setDrawerOpen(false) }
        >
          <Sidebar />
        </CustomDrawer>
      </div>
    );
  }

  // rendering due to cookie auth token availability
  return !token ? null : renderBody();
}
