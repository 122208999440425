import React, { useState, useEffect } from 'react';

import './forecast.css';
import '../styles/custom-scrollbar.css';
import StepId from '../../data/step-id.enum';
import ErrorScreen from '../error-screen';
import ForecastContent from './content';

function Loading() {
  return (
    <div
      data-testid="forecast-loading"
      className="forecast__loading"
    >
      <i className="forecast__spinner fas fa-circle-notch fa-spin"></i>
    </div>
  );
}

export default function ForecastView({ content }) {
  const [_isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [_content, setContent] = useState({
    brand: null,
    model: null,
    year: null,
    priceFrom: null,
    priceTill: null,
  });

  useEffect(() => {
    const forecastRecord = content
      .find(x => x.stepId === StepId.FORECAST);
    if (forecastRecord) {
      const { isError, content } = forecastRecord;
      if (isError) {
        setError(true);
      } else if (content) {
        setContent(content);
        setSuccess(true);
      }
    }
  }, [content]);

  function renderBody() {
    if (_isError) {
      return <ErrorScreen />;
    }
    if (isSuccess) {
      return <ForecastContent {..._content} />;
    }
    return <Loading />;
  }

  return (
    <div
      data-testid="forecast"
      className="forecast custom-scrollbar"
    >
      { renderBody() }
    </div>
  );
}
