import React from 'react';

import styles from './item.module.css';


export default function Item({ icon, label }) {
  return (
    <div className={styles.item}>
      <div className={styles.item_image_wrapper}>
        <img
          className={styles.item_image}
          src={icon}
          alt={label}
        />
      </div>
      <div className={styles.item_text}>
        { label }
      </div>
    </div>
  );
}
