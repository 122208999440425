import RequestStatus from '../lib/request-status.enum';
import EvaluatorAction from './action-type.enum';

//import { machineData0, plotData0, predictionData0 } from './test-data';

// test
/*const initialState = {
  requestStatus: RequestStatus.SUCCESS,
  machineData: machineData0,
  plotData: plotData0,
  predictionData: predictionData0,
};*/

// prod
const initialState = {
  requestStatus: RequestStatus.IDLE,
  machineData: null,
  plotData: null,
  predictionData: null,
};


export default function evaluatorReducer(state = initialState, action) {
  switch (action.type) {
    case EvaluatorAction.FORM_SUBMIT: {
      const machineData = action.payload.values;
      return Object.assign({}, state, { machineData });
    }
    case EvaluatorAction.EVAL_REQUEST: {
      return Object.assign({}, state, {
        requestStatus: RequestStatus.PENDING,
        plotData: null,
        predictionData: null,
      });
    }
    case EvaluatorAction.EVAL_SUCCESS: {
      const { plotData, predictionData } = action.payload;
      return Object.assign({}, state, {
        requestStatus: RequestStatus.SUCCESS,
        plotData,
        predictionData,
      });
    }
    case EvaluatorAction.EVAL_ERROR: {
      return Object.assign({}, state, {
        requestStatus: RequestStatus.ERROR,
        plotData: null,
        predictionData: null,
      });
    }
    default: return state;
  }
}
