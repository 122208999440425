import React from 'react';
import { Link } from '@reach/router';

import './network.css';
import mapImage from './map.png';
import mapPin from './pin-map.svg';

function MapPin({ top, left }) {
  return (
    <img
      className="network-map__pin"
      style={{ top: `${top}%`, left: `${left}%` }}
      src={mapPin}
      alt="Pin"
    />
  );
}

/**
 * Network (map) component.
 */
export default function Network() {
  return (
    <div className="network">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 order-md-2 col-xl-5 offset-xl-1">
            <div className="network-map">
              <img
                className="network-map__map-image"
                src={mapImage}
                alt="Map"
              />
              
              <MapPin top={15} left={43} />
              <MapPin top={21} left={65} />
              <MapPin top={24} left={73} />
              <MapPin top={27} left={30} />
              <MapPin top={28} left={68} />
              <MapPin top={32} left={38} />
              <MapPin top={36} left={25} />
              <MapPin top={39} left={82} />
              <MapPin top={42} left={57} />
              <MapPin top={50} left={19} />
              <MapPin top={52} left={65} />
              <MapPin top={53} left={39} />
              <MapPin top={57} left={18} />
              <MapPin top={60} left={50} />
              <MapPin top={64} left={26} />
              <MapPin top={68} left={65} />
              <MapPin top={72} left={70} />
              <MapPin top={73} left={46} />
              <MapPin top={78} left={28} />
              <MapPin top={84} left={26} />
              <MapPin top={86} left={72} />
              <MapPin top={88} left={50} />
            </div>
          </div>

          <div className="network__text-block col-12 col-md-6 order-md-1">
            <h2 className="network__heading mb-3">Händlernetzwerk</h2>

            <p className="network__paragraph">
              Werden Sie Teil unserer innovativen Remarketing-Plattform 
              für gebrauchte Land- &amp; Baumaschinen!
            </p>
            <p className="network__paragraph">
              Vernetzen Sie sich mit anderen Händlern und tauschen Sie 
              untereinander Fachkenntnisse, Maschinendaten, Bewertungen 
              und weitere Informationen aus.
            </p>
            <p className="network__paragraph">
              Unsere Devise lautet: Besser miteinander, statt gegeneinander!
            </p>

            <div style={{ paddingTop: '1rem' }}>
              <Link to="/sign-up" className="hdlr1-button">
                Netzwerk beitreten
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
