import React from 'react';

import './imprint.css';

export default function Imprint() {
  return (
    <div className="imprint container">
      <h1 className="imprint__heading">Impressum</h1>

      <p>www.händler1.de ist ein Projekt der…</p>

      <p>
        <b>Rheinlandmaschinen UG</b><br />
        <span>Scheibenstraße 49</span><br />
        <span>40476 Düsseldorf</span>
      </p>

      <p>
        <b>vertreten durch den Geschäftsführer:</b><br />
        <span>Herr Marten Telake</span>
      </p>

      <p>
        <b>Kontakt:</b><br />
        <span>Telefon:		0151 112 075 39</span><br />
        <span>Email:		info@händler1.de</span><br />
        <span>Webseite:	www.händler1.de</span>
      </p>

      <p>
        <b>Registereintrag:</b><br />
        <span>Registergericht:		Amtsgericht Düsseldorf</span><br />
        <span>Registernummer:		HR B 87764</span>
      </p>

      <p>
        <b>Umsatzsteuer-ID:</b><br />
        <span style={{ color: 'gray' }}>
          Umsatzsteuer -Identifikationsnummer gemäß §27 
          a Umsatzsteuergesetz: DE 327025663
        </span>
      </p>
    </div>
  );
}
