import React, { useState } from 'react';

import './styles/index.css';
import Evaluator from './evaluator';
import Password from './password';

/**
 * Dashboard sub-app main component.
 */
export default function Dashboard() {
  const [password, setPassword] = useState();
  const [incorrect, setIncorrect] = useState(false);

  const secretPassword = 'Bewertung';  // yes, this one

  function handleAttempt(pswd) {
    setPassword(pswd);
    setIncorrect(pswd !== secretPassword);
  }

  function renderBody() {
    if (password === secretPassword) {
      return <Evaluator />;
    }
    return (
      <Password
        incorrect={incorrect}
        onAttempt={handleAttempt}
      />
    );
  }

  return (
    <div>
      { renderBody() }
    </div>
  );
}
