import React from 'react';
import { Modal } from 'antd';

import Operator from '.';
import close from './close.svg';


export default function OperatorModal(props) {
  return (
    <Modal
      title={null}
      footer={null}
      centered={true}
      destroyOnClose={true}
      width={400}
      maskStyle={{ backgroundColor: 'rgba(250, 250, 250, 0.5)' }}
      closeIcon={<img src={close} alt="Close" />}
      {...props}
    >
      <Operator />
    </Modal>
  );
}
