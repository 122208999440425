import React, { useState, useEffect } from 'react';
import { Link } from '@reach/router';

import styles from './cookie-bar.module.css';


const cookieConsentKey = 'haendler1Cookie';


export default function CookieBar() {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const cookies = document.cookie.split(';');
    for (const c of cookies) {
      const key = c.split('=')[0];
      if (key.trim() === cookieConsentKey) {
        setVisible(false);
        break;
      }
    }
  }, []);

  function handleAccept() {
    document.cookie = `${cookieConsentKey}=true; expires=Thu, 31 Dec 2099 00:00:00 UTC`;
    setVisible(false);
  }

  if (!visible) {
    return null;
  }
  return (
    <div className={styles.cookie_bar}>
      <div className="container-fluid">
        <div className={styles.container}>
          <div className={styles.text_block}>
            <span>
              Wir verwenden Cookies, um die Nutzung der Seite für Sie zu optimieren. 
              Wenn Sie unsere Seite weiterhin nutzen möchten, stimmen Sie bitte der Cookie Nutzung 
              zu. <Link className={styles.link} to="/privacy-policy">
                Datenschutzerklärung
              </Link>.
            </span>
          </div>

          <div>
            <button
              className="hdlr1-button"
              style={{ margin: '0 .5rem .5rem' }}
              onClick={ e => handleAccept() }
            >
              Akzeptieren
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
