import React from 'react';
import { Link } from '@reach/router';

import './banner.css';
import play from './play-button.svg';
import bannerImage from './banner-md-1000px.png';
import bannerImageXl from './banner-xl-900px.png';
import bgImage from './background.png';


export default function Banner() {
  return (
    <div className="banner" style={{ backgroundImage: `url(${bgImage})` }}>
      <div style={{ position: 'relative' }}>
        <div className="banner__image-wrapper">
          <img
            className="banner__image"
            src={bannerImage}
            alt="Banner"
          />
          <img
            className="banner__image_xl"
            src={bannerImageXl}
            alt="Banner"
          />
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-9 col-md-8 col-lg-6">
              <div className="banner__heading">
                Einfach risikolos Landmaschinen handeln!
              </div>

              <div className="banner__text">
                Händler1 bietet seinen Partnern einen exklusiven B2B-Marktplatz 
                für gebrauchte Landtechnik. Verkaufen Sie jetzt schnell und 
                einfach Ihre in Zahlung genommenen Maschinen über Händler1. 
                Alternativ finden Sie in unserem großen Angebotspool an 
                geprüfter Landtechnik die passende Gebrauchtmaschine für 
                jeden Kunden.
              </div>

              <div style={{ marginBottom: '2.5rem' }}>
                <Link to="/#how-it-works" className="banner__show-link">
                  <img src={play} alt="Play" />
                  <span className="banner__show-link-text">
                    Erklärvideo ansehen
                  </span>
                </Link>
              </div>

              <div>
                <Link to="/sign-up" className="hdlr1-button">
                  Jetzt Kostenlos registrieren
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
