import React from 'react';
import { Link } from '@reach/router';

import logo from './logo.svg';


export default function SidebarHeader() {
  return (
    <div style={{ padding: '1.5rem' }}>
      <Link to="/">
        <img
          src={logo}
          alt="Logo"
          style={{ height: '1.5rem' }}
        />
      </Link>
    </div>
  );
}
