import React from 'react';
import { Link } from '@reach/router';

import './sign-up.css';
import Input from '../../components/input';


/**
 * Sign-up component (form).
 */
export default function SignUpForm({ onFormSubmit }) {
  function serialize(eventTarget) {
    const values = {};
    for (const prop of eventTarget) {
      const { name } = prop;
      if (name) {
        values[name] = prop.value;
      }
    }
    return values;
  }

  function handleSubmit(e) {
    e.preventDefault();
    const passwordPrimary = e.target.password;
    const passwordConfirm = e.target.password_confirm;
    if (passwordConfirm.value !== passwordPrimary.value) {
      passwordConfirm
        .setCustomValidity('Passwörter stimmen nicht überein');  // HARDCODED TEXT LABEL
      e.target.reportValidity();
    } else {
      const formData = serialize(e.target);
      onFormSubmit(formData);
    }
  }

  function resetErrors(e) {
    if (e.target.name === 'password_confirm') {
      // removing password_confirm error if any
      e.target.setCustomValidity('');
    }
  }

  return (
    <div className="sign-up">
      <div className="sign-up__container">
        <div className="sign-up__header">
          <h2 className="sign-up__heading">Jetzt kostenlos anmelden!</h2>

          <p className="sign-up__subheading">
            Erhalten Sie exklusiven Zugang zu unseren 
            digitalen Werkzeugen und geprüften Maschinen.
          </p>
        </div>

        <form onSubmit={handleSubmit} onChange={resetErrors}>
          <div className="row sign-up__group-1">
            <div className="col-12">
              <Input
                type="email"
                name="email"
                placeholder="E-Mail"
                prefix={<i className="icon-envelope"></i>}
                filled
                required
              />
            </div>

            <div className="col-12 col-md-6">
              <Input
                type="password"
                name="password"
                placeholder="Passwort"
                prefix={<i className="icon-padlock"></i>}
                filled
                required
              />
            </div>

            <div className="col-12 col-md-6">
              <Input
                type="password"
                name="password_confirm"
                placeholder="Passwort wiederholen"
                prefix={<i className="icon-padlock"></i>}
                filled
                required
              />
            </div>
          </div>

          <div className="sign-up__separator"></div>

          <div className="row sign-up__group-2">
            <div className="col-12">
              <Input
                type="text"
                name="company"
                placeholder="Firmenname"
                prefix={<i className="icon-company"></i>}
                filled
                required
              />
            </div>

            <div className="col-12 col-md-7">
              <Input
                type="text"
                name="street"
                placeholder="Straße"
                prefix={<i className="icon-location"></i>}
                filled
                required
              />
            </div>

            <div className="col-12 col-md-5">
              <Input
                type="text"
                name="house_number"
                placeholder="Hausnummer"
                prefix={<i className="icon-location"></i>}
                filled
                required
              />
            </div>

            <div className="col-12 col-md-4">
              <Input
                type="text"
                name="zip"
                placeholder="Postleitzahl"
                prefix={<i className="icon-location"></i>}
                filled
                required
              />
            </div>
            <div className="col-12 col-md-4">
              <Input
                type="text"
                name="city"
                placeholder="Ort"
                prefix={<i className="icon-location"></i>}
                filled
                required
              />
            </div>
            <div className="col-12 col-md-4">
              <Input
                type="text"
                name="state"
                placeholder="Land"
                prefix={<i className="icon-location"></i>}
                filled
                required
              />
            </div>

            <div className="col-12">
              <Input
                type="text"
                name="phone"
                placeholder="Telefon-Nr."
                prefix={<i className="icon-phone"></i>}
                filled
                required
              />
            </div>
          </div>

          <div className="sign-up__submit-wrapper">
            <button type="submit" className="hdlr1-button">
              Werden sie verifizierter händler
            </button>
          </div>
        </form>

        <p className="sign-up__agree-block">
          Durch das Absenden Ihrer<br /> Registrierungsanfrage akzeptieren Sie 
          unsere <a className="sign-up__agree-link" href="/terms-of-service" target="_blank">
            allgemeinen Geschäftsbedingungen.
          </a>
        </p>
      </div>

      <div className="sign-up__login-block">
        <p>
          Sind Sie schon angemeldet?
        </p>
        <p>
          <Link
            className="sign-up__login-link"
            to="/login"
          >
            Einloggen
          </Link>
        </p>
      </div>
    </div>
  );
}
