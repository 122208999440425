import React from 'react';

import './testimonials.css';
import person1 from './person-1.png';
import person2 from './person-2.png';
import person3 from './person-3.png';

export default function Testimonials() {
  return (
    <div className="testimonials">
      <div className="testimonials__top-overlay"></div>

      <div className="container">
        <div className="row">
          <div className="testimonial col-12 col-md-6 col-lg-4">
            <div className="testimonial__photo">
              <img
                className="testimonial__photo-image"
                src={person1}
                alt="Customer"
              />
            </div>
            <div className="testimonial__text">
              <div className="testimonial__name">Herr Schultz</div>
              <div className="testimonial__feedback">
                Nur gemeinsam kann man den schnelllebigen Handel 
                gerecht werden und sämtliche Risiken im Handel mit 
                gebrauchten Land- &amp; Baumaschinen ausschließen!
              </div>
            </div>
          </div>
          <div className="testimonial col-12 col-md-6 col-lg-4">
            <div className="testimonial__photo">
              <img
                className="testimonial__photo-image"
                src={person2}
                alt="Customer"
              />
            </div>
            <div className="testimonial__text">
              <div className="testimonial__name">Herr Schmidt</div>
              <div className="testimonial__feedback">
                Mit Hilfe unseres Portals ermöglichen wir eine 
                unkomplizierte einfache Kommunikation,die Erweiterung 
                des Kundenstamms und neue Geschäftschancen für 
                unsere Nutzer.
              </div>
            </div>
          </div>
          <div className="testimonial col-12 col-lg-4">
            <div className="testimonial__photo">
              <img
                className="testimonial__photo-image"
                src={person3}
                alt="Customer"
              />
            </div>
            <div className="testimonial__text">
              <div className="testimonial__name">Frau Müller</div>
              <div className="testimonial__feedback">
                Durch die umfangreichen und variantenreichen Maschinen 
                am Markt ist eine allgemeine Fachkenntnis über alle 
                Hersteller schlichtweg nicht möglich, daher ist ein 
                Austausch zwischen den Händlern zwingend erforderlich.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
