import React from 'react';

import './valuation-header.css';
import Predictor from '../../../predictor';

export default function Header() {
  return (
    <div className="valuation-header">
      <div className="valuation-header__container container">
        <Predictor />
      </div>
    </div>
  );
}
