import React from 'react';

import './not-found.css';

export default function NotFound() {
  return (
    <div className="not-found hdlr1-article container">
      <h1 className="not-found__heading">404 Not Found</h1>

      <p>
        Die angeforderte Seite existiert nicht.
      </p>

      <p>
        <a className="not-found__link" href="/">Zur Startseite</a>
      </p>
    </div>
  );
}
