import { call, select } from 'redux-saga/effects';

import { indexSelector } from '../predictor/selectors';

export default function* getStepSaga(predictorParams, values, back = false) {
  const { steps, availabilities } = predictorParams;
  const index = yield select(indexSelector);
  let step = null;
  let newIndex = index;
  while (!step) {
    back ? newIndex-- : newIndex++;
    const _step = steps[newIndex];
    const { id } = _step;
    const availability = availabilities.find(x => x.stepId === id);
    if (!availability) {
      step = steps[newIndex];
    } else {
      const isAvailable = yield call(availability.getAvailable, values);
      if (isAvailable) {
        step = steps[newIndex];
      }
    }
  }
  return {
    step,
    index: newIndex,
  };
}
