import tractors from './tractors.json';
import otherBrands from './other.json';

const allBrands = [];

for (const tractor of tractors) {
  const { id, label, modelGroups } = tractor;
  let newModelGroups = [].concat(modelGroups);
  const another = otherBrands.find(x => x.id === id);
  if (another) {
    const index = otherBrands.indexOf(another);
    otherBrands.splice(index, 1);
    newModelGroups = [].concat(modelGroups).concat(another.modelGroups);
  }
  const newBrand = {
    id,
    label,
    modelGroups: newModelGroups,
  };
  allBrands.push(newBrand);
}

export default allBrands.concat(otherBrands);
