import React from 'react';

import styles from './operator.module.css';
import marten from './marten.png';
import phone from './phone.svg';


export default function Operator() {
  return (
    <div className={styles.operator}>
      <h2 className={styles.heading}>Kann ich Ihnen helfen?</h2>

      <div style={{ marginBottom: '.5rem' }}>
        <img src={marten} alt="Marten Telake" />
      </div>

      <div className={styles.name}>Marten Telake</div>

      <div className={styles.phone_block}>
        <img src={phone} alt="Phone" />
        <span className={styles.phone_text}>0151 112 075 39</span>
      </div>

      <div>
        <a href="mailto:info@haendler1.de" className="dshb-button">
          Kontakt
        </a>
      </div>
    </div>
  );
}
