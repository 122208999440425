import StepId from '../../step-id.enum';
import { getValueForStep } from '../utils';
import callContactApi from '../../../../utils/apiCaller';


export default async function getFinishContent(values) {
  // getting user email from value registry
  const email = getValueForStep(values, StepId.SIGN_UP, 'email');

  const brand = getValueForStep(values, StepId.BRAND);
  const model = getValueForStep(values, StepId.MODEL);
  const year = getValueForStep(values, StepId.YEAR);
  //const hours = getValueForStep(values, StepId.HOUR);
  //const power = getValueForStep(values, StepId.POWER);

  let priceFrom = null;
  let priceTill = null;
  const forecastData = values.find(x => x.stepId === StepId.FORECAST);
  if (forecastData) {
    const { value } = forecastData;
    priceFrom = value.priceFrom;
    priceTill = value.priceTill;
  }

  const evaluationData = {
    brand,
    model,
    year,
    //hours,
    //power,
    priceFrom,
    priceTill,
  }

  // composing email content
  const content =
    'This email address has been left by a CUSTOMER via the PREDICTOR WIZARD.';

  // composing body
  const body = {
    ...evaluationData,
    email,
    content,
  };

  // sending the email to backend
  await callContactApi('lead', 'POST', body);
}
