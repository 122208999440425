import React, { useState } from 'react';

import '../../../components/video/video.css';
import '../../../components/video/video-screen.css';
import tablet from './tablet.png';
import tabletSm from './tablet-sm.png';
import VideoModal from '../../../components/video/modal';

export default function Video() {
  const [isModalVisible, setModalVisible] = useState(false);

  return (
    <div className="video">
      <div className="video__block-small">
        <img
          className="video__image"
          src={tabletSm}
          alt="Video"
        />
        <div
          className="video__screen video__screen_small"
          onClick={ e => setModalVisible(true) }
        ></div>
      </div>
      <div className="video__block-large">
        <img
          className="video__image"
          src={tablet}
          alt="Video"
        />
        <div
          className="video__screen video__screen_large"
          onClick={ e => setModalVisible(true) }
        ></div>
      </div>

      <div className="video__text container">
        <div className="row">
          <div className="col-12 col-lg-6 offset-lg-6 col-xl-5 offset-xl-7">
            <h2 className="video__heading">So funktioniert’s</h2>

            <p className="video__paragraph">
              Als registrierter Händler erhalten Sie nach Angabe 
              Ihrer Maschinendaten automatisch eine zustandsabhängige 
              Marktpreisprognose.
            </p>

            <p className="video__paragraph">
              In unseren Abfrageprozess müssen folgende Daten angegeben werden:
            </p>

            <ul className="video__paragraph">
              <li>Maschinentyp</li>
              <li>Hersteller</li>
              <li>Modell</li>
              <li>Baujahr</li>
              <li>Leistung</li>
              <li>Betriebsstunden</li>
              <li>Reifenzustand</li>
              <li>Zusatzausstattung</li>
            </ul>

            <p className="video__paragraph">
              Je nach Maschinenart können die Eigenschaften der Maschine 
              variieren. Am Ende des Abfrageprozesses erhalten Sie eine 
              bereinigte Marktpreisprognose, sowie die Möglichkeit, die 
              Maschine direkt im Händlerportal zur weiteren Bewertung oder 
              zum Verkauf zu teilen.
            </p>

            <div style={{ paddingTop: '1rem' }}>
              <button
                className="hdlr1-button"
                onClick={ () => setModalVisible(true) }
              >
                Mehr Informationen
              </button>
            </div>
          </div>
        </div>
      </div>

      <VideoModal
        visible={isModalVisible}
        onClose={ () => setModalVisible(false) }
      />
    </div>
  );
}
