import React, { useEffect, useRef } from 'react';

import '../forecast-content.css';
import '../../styles/predictor.css';
import StepId from '../../../data/step-id.enum';


export default function ForecastAutomaticView({
  brand, model, year, priceFrom, priceTill,
  values, onProceed, onRestart,
}) {
  const priceFromFormatted = priceFrom.toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const priceTillFormatted = priceTill.toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const proceedValue = {
    priceFrom,
    priceTill,
  };

  const autoTimeout = useRef(null);

  function handleButtonClick(shouldProceed) {
    clearTimeout(autoTimeout.current);
    shouldProceed ? onProceed(proceedValue) : onRestart();
  }

  // auto-forwarding to the email step after 5 seconds (first time only)
  useEffect(() => {
    const forecastRecord = values
      .find(x => x.stepId === StepId.FORECAST);
    if (!forecastRecord) {
      autoTimeout.current = setTimeout(() => onProceed(proceedValue), 5000);
    }
  }, [onProceed, proceedValue, values]);

  return (
    <div data-testid="forecast-automatic">
      <div className="forecast-content__heading">
        <div className="forecast-content__text-large">
          Ihre Maschine:
        </div>
        {' '}
        <div className="forecast-content__text-large">
          { brand } { model }, Baujahr { year }
        </div>
      </div>
      <div className="forecast-content__text-normal" style={{ marginTop: '.5rem' }}>
        hat einen ungefähren Marktwert von:
      </div>

      <div className="forecast-content__price-block">
        <div className="forecast-content__price-text">
          { priceFromFormatted }
        </div>
        <b
          className="forecast-content__text-normal"
          style={{ margin: '0 1rem' }}
        >
          bis
        </b>
        <div className="forecast-content__price-text">
          { priceTillFormatted }
        </div>
      </div>

      <div className="forecast-content__feedback-text">
        Möchten Sie Zugang zu einer genaueren Bewertung Ihrer Landmaschine?
      </div>

      <div className="forecast-content__button-block">
        <button
          className="predictor__button predictor__button_accent"
          onClick={ e => handleButtonClick(true) }
        >
          Ja
        </button>
        <span style={{ marginRight: '.5rem' }}></span>
        <button
          className="predictor__button predictor__button_default"
          onClick={ e => handleButtonClick(false) }
        >
          Nein
        </button>
      </div>
    </div>
  );
}
