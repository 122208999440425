import React from 'react';

import loadStyles from './loading.module.css';
import RequestStatus from '../../lib/request-status.enum';
import ResultsSuccess from '../results-success';


function Loading() {
  return (
    <div className={loadStyles.loading}>
      <div>
        <i className="fas fa-circle-notch fa-spin"></i>
      </div>
      <div className={loadStyles.loading_text}>
        Wird geladen...
      </div>
    </div>
  );
}


function ErrorScreen({ onRetry }) {
  function handleClick(e) {
    e.preventDefault();
    onRetry();
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <span style={{ color: '#c71e1e' }}>
        An error occurred while requesting evaluation.
      </span>
      {' '}
      <a href="/" onClick={handleClick}>Retry</a>
    </div>
  );
}


export default function ResultsView({ requestStatus, machineData, onRetry }) {
  function renderBody() {
    if (requestStatus === RequestStatus.ERROR) {
      return <ErrorScreen
        onRetry={ () => onRetry(machineData) }
      />;
    }
    if (requestStatus === RequestStatus.PENDING) {
      return <Loading />;
    }
    if (requestStatus === RequestStatus.SUCCESS) {
      return <ResultsSuccess />;
    }
  }

  return (
    <div>
      { renderBody() }
    </div>
  );
}
