import claasAxion from './images/claas-axion.png';
import mcX6 from './images/mc-cormick-x6.png';
import nhTm190 from './images/new-holland-tm-190.png';
import fendt828Vario from './images/fendt-828-vario.png';
import dfWarrior from './images/deutz-fahr-7250-ttv-warrior.jpg';
import fendt820 from './images/fendt-820-vario-tms.jpg';
import caseMxm155 from './images/case-ih-mxm-155.jpg';
import df6190ttv from './images/deutz-fahr-6190-ttv.jpg'
import dfAgrotron120 from './images/deutz-fahr-agrotron-120.jpg';
import mcmc120 from './images/mc-cormick-mc-120.jpg';
import kubotaM7171kvt from './images/kubota-m7171-premium-kvt.jpg';
import steyr4100ecotech from './images/steyr-4100-multi-ecotech.jpg';
import case5140maxxum from './images/case-ih-5140-maxxum.jpg';
import caseMx135 from './images/case-ih-mx-135.jpg';
import df6165powershift from './images/deutz-fahr-6165-powershift.jpg';
import dfM625 from './images/deutz-fahr-m625.jpg';


const defaultLocation = 'Düsseldorf 40210';


const exampleMachines = [
  {
    image: claasAxion,
    brand: 'Claas',
    model: 'Axion',
    //description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    location: defaultLocation,
    priceLower: 35000,
    priceUpper: 42000,
  },
  {
    image: mcX6,
    brand: 'McCormick',
    model: 'X6',
    //description: 'Lorem ipsum dolor sit amet, consectetur.',
    location: defaultLocation,
    priceLower: 32000,
    priceUpper: 38000,
  },
  {
    image: nhTm190,
    brand: 'New Holland',
    model: 'TM 190',
    /*description: `
      Lorem ipsum dolor sit amet, consectetur 
      adipiscing elit. Cras gravida sed.
    `,*/
    location: defaultLocation,
    priceLower: 18000,
    priceUpper: 22000,
  },
  {
    image: fendt828Vario,
    brand: 'Fendt',
    model: '828 Vario',
    //description: 'Lorem ipsum dolor sit amet.',
    location: defaultLocation,
    priceLower: 60000,
    priceUpper: 68000,
  },

  {
    image: dfWarrior,
    brand: 'Deutz-Fahr',
    model: '7250 TTV Warrior',
    location: defaultLocation,
    priceLower: 10000,
    priceUpper: 90000,
  },
  {
    image: fendt820,
    brand: 'Fendt',
    model: '820 Vario TMS',
    location: defaultLocation,
    priceLower: 10000,
    priceUpper: 90000,
  },
  {
    image: caseMxm155,
    brand: 'Case IH',
    model: 'MXM 155',
    location: defaultLocation,
    priceLower: 10000,
    priceUpper: 90000,
  },
  {
    image: df6190ttv,
    brand: 'Deutz-Fahr',
    model: '6190 TTV',
    location: defaultLocation,
    priceLower: 10000,
    priceUpper: 90000,
  },
  {
    image: dfAgrotron120,
    brand: 'Deutz-Fahr',
    model: 'Agrotron 120',
    location: defaultLocation,
    priceLower: 10000,
    priceUpper: 90000,
  },
  {
    image: mcmc120,
    brand: 'McCormick',
    model: 'MC 120',
    location: defaultLocation,
    priceLower: 10000,
    priceUpper: 90000,
  },
  {
    image: kubotaM7171kvt,
    brand: 'Kubota',
    model: 'M7171 Premium KVT',
    location: defaultLocation,
    priceLower: 10000,
    priceUpper: 90000,
  },
  {
    image: steyr4100ecotech,
    brand: 'Steyr',
    model: '4100 Multi Ecotech',
    location: defaultLocation,
    priceLower: 10000,
    priceUpper: 90000,
  },
  {
    image: case5140maxxum,
    brand: 'Case IH',
    model: '5140 Maxxum',
    location: defaultLocation,
    priceLower: 10000,
    priceUpper: 90000,
  },
  {
    image: caseMx135,
    brand: 'Case IH',
    model: 'MX 135',
    location: defaultLocation,
    priceLower: 10000,
    priceUpper: 90000,
  },
  {
    image: df6165powershift,
    brand: 'Deutz-Fahr',
    model: '6165 Powershift',
    location: defaultLocation,
    priceLower: 10000,
    priceUpper: 90000,
  },
  {
    image: dfM625,
    brand: 'Deutz-Fahr',
    model: 'M 625',
    location: defaultLocation,
    priceLower: 10000,
    priceUpper: 90000,
  },
];

export default exampleMachines;
