import React, { useState } from 'react';

import './marketplace.css';
import Banner from './banner';
import Panel from './panel';
import MachineCard from './machine-card';
import Alert from './alert';
import SignUpModal from '../../components/sign-up/modal';

import exampleMachines from '../../data/example-machines';


/**
 * Marketplace page component.
 */
export default function Marketplace() {
  const [isModalOpen, setModalOpen] = useState(false);

  function renderMachines() {
    return exampleMachines.map((x, i) => (
      <div
        key={i}
        className="col-12 col-md-6 col-xl-4"
        onClick={ e => setModalOpen(true) }
      >  
        <MachineCard {...x} />
      </div>
    ));
  }

  return (
    <div>
      <Banner />
      
      <div className="marketplace__container container">
        <div className="row">
          <div className="col-12 col-lg-4 col-xl-3">
            <Panel onFilterMouseDown={ () => setModalOpen(true) } />
          </div>

          <div className="col-12 col-lg-8 col-xl-9">
            <div className="row">
              { renderMachines() }
            </div>

            <Alert />
          </div>
        </div>
      </div>

      <SignUpModal
        visible={isModalOpen}
        onCancel={ () => setModalOpen(false) }
      />
    </div>
  );
}
