import React, { useState } from 'react';

import './contact.css';
import bgImage from './background.png';
import ContactForm from './form';
import ThankYou from './thank-you';
import callApi from '../../utils/call-api';


function ThankYouContainer() {
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  };
  return (
    <div style={style}>
      <ThankYou />
    </div>
  );
}


/**
 * Contact form container component.
 */
export default function Contact() {
  const [sent, setSent] = useState(false);
  const [height, setHeight] = useState('auto');

  async function handleSend(contactData) {
    const container = document.querySelector('.contact__container');
    setHeight(container.clientHeight);
    setSent(true);
    
    // TODO: loading & error states
    callApi('/contact', 'post', contactData)
      .catch(err => {
        if (process.env.NODE_ENV === 'development') {
          console.error(err);
        }
      });
  }

  function renderContent() {
    return sent ?
      <ThankYouContainer /> :
      <ContactForm onSend={handleSend} />;
  }

  return (
    <div id="contact" className="contact" style={{ backgroundImage: `url(${bgImage})` }}>
      <div className="contact__container container" style={{ height }}>
        { renderContent() }
      </div>
    </div>
  );
}
