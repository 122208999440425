import React from 'react';

import styles from './navbar.module.css';
import logo from './logo.svg';


export default function Navbar({ onMenuClick }) {
  return (
    <nav className={styles.navbar}>
      <button
        className={styles.menu_button}
        onClick={ e => onMenuClick() }
      >
        <i className={`${styles.menu_icon} icon-menu`}></i>
      </button>
      
      <a href="/" style={{ margin: '.25rem 0' }}>
        <img
          className={styles.logo}
          src={logo}
          alt="Logo"
        />
      </a>
    </nav>
  );
}
