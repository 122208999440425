import React from 'react';

import './top.css';
import call from './call.svg';
import envelope from './envelope-2.svg';

export default function Top() {
  return (
    <div className="top">
      <span className="top__text">
        <span style={{ fontWeight: 300 }}>
          Gibt es Fragen?
        </span>
        {' '}
        <span style={{ fontWeight: 500 }}>
          Kontaktieren Sie uns wie und wann Sie möchten!
        </span>
      </span>

      <span className="top__contact" style={{ marginRight: '.5rem' }}>
        <img
          className="top__contact-icon"
          src={call}
          alt="Phone"
        />
        <span className="top__contact-text">
          0151 112 075 39
        </span>
      </span>
      <span className="top__contact">
        <img
          className="top__contact-icon"
          src={envelope}
          alt="Email"
        />
        <a
          href="mailto:info@haendler1.de"
          className="top__contact-text"
        >
          info@haendler1.de
        </a>
      </span>
    </div>
  );
}
