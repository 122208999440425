import React from 'react';
import ReactDOM from 'react-dom';
import 'babel-polyfill'; 
import 'antd/dist/antd.css';  // ant design
// bootstrap overrides ant
import 'bootstrap/dist/css/bootstrap-reboot.css'; // bootstrap
import 'bootstrap/dist/css/bootstrap-grid.css';
import '@fortawesome/fontawesome-free/css/all.css'; // font awesome

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
