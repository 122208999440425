import React, { useRef } from 'react';
import { Link } from '@reach/router';
import { Carousel } from 'antd';

import './offers.css';
import Offer from './offer';
import prev from './prev.svg';
import next from './next.svg';

import exampleMachines from '../../../data/example-machines';


function renderOffers(offers) {
  return offers.map((x, i) => (
    <Offer key={i} {...x} />
  ));
}


/**
 * Offers component (carousel).
 */
export default function Offers() {
  const carousel = useRef(null);

  function handlePrevClick(e) {
    carousel.current.prev();
  }
  function handleNextClick(e) {
    carousel.current.next();
  }

  return (
    <div className="offers">
      <div className="container" style={{ textAlign: 'center', marginBottom: '2.5rem' }}>
        <div className="offers__heading">
          Neueste Angebote auf Händler1.de!
        </div>
        <div className="offers__subheading">
          Land- und Baumaschinen ausschließlich von Händlern geprüft und inseriert!
        </div>
      </div>

      <div className="offers__carousel-container container">
        <button className="offers__carousel-button" onClick={handlePrevClick}>
          <img src={prev} alt="Previous" />
        </button>

        <Carousel
          prefixCls="ant-carousel offers__carousel"
          ref={carousel}
          slidesToShow={4}
          slidesToScroll={1}
          adaptiveHeight={false}
          infinite={true}
          dots={false}
          responsive={[
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]}
        >
          { renderOffers(exampleMachines) }
        </Carousel>

        <button className="offers__carousel-button" onClick={handleNextClick}>
          <img src={next} alt="Next" />
        </button>
      </div>

      <div className="container" style={{ textAlign: 'center' }}>
        <Link to="/marketplace" className="hdlr1-button">
          Zu unseren aktuellen Angeboten
        </Link>
      </div>
    </div>
  );
}
