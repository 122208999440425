import React, { useEffect } from 'react';
import vegaEmbed from 'vega-embed';

import styles from './plot.module.css';

/**
 * Vega-Demo page component.
 */
export default function PlotView({ plotData }) {
  useEffect(() => {
    //plotData.config = {};
    plotData.width = 'container';
    plotData.height = 'container';
    plotData.autosize = 'fit';
    vegaEmbed('#price-chart', plotData);
  });

  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.plot}>
          <div
            id="price-chart"
            style={{ width: '100%', height: '100%' }}
          ></div>
        </div>
      </div>
    </div>
  );
}
