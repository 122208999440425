import React from 'react';

import styles from './counter.module.css';

export default function Counter({ label }) {
  return (
    <div>
      <div className={styles.label}>
        { label }
      </div>
      <div className={styles.counter_wrapper}>
        <button className="dshb-button dshb-button_round">-</button>
        <input
          type="number"
          placeholder="Zahl eingeben"
          className={`${styles.input} dshb-input`}
        />
        <button className="dshb-button dshb-button_round">+</button>
      </div>
    </div>
  );
}
