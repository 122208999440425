import React from 'react';

import './thank-you.css';
import email from './email.svg';

export default function ThankYou() {
  return (
    <div className="thank-you">
      <div className="thank-you__container">
        <div className="thank-you__image-block">
          <img src={email} alt="E-Mail" />
        </div>

        <div className="thank-you__text-block">
          <h2 className="thank-you__heading">Vielen Dank!</h2>
          <p className="thank-you__paragraph">
            Wir werden uns in Kürze mit <br />
            Ihnen in Verbindung setzen.
          </p>
        </div>
      </div>
    </div>
  );
}
