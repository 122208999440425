import { connect } from 'react-redux';

import ResultsView from './view';
import EvaluatorAction from '../../redux/action-type.enum';

function stp(s) {
  return {
    requestStatus: s.evaluator.requestStatus,
    machineData: s.evaluator.machineData,
  };
}

function dtp(d) {
  return {
    onRetry: machineData => d({
      type: EvaluatorAction.FORM_SUBMIT,
      payload: {
        values: machineData,
      },
    }),
  };
}

const Results = connect(
  stp,
  dtp,
)(ResultsView);

export default Results;
