import React from 'react';
import Item from './item';

import styles from './item.module.css';


export default function MenuButton({
  icon, label, disabled = false, onButtonClick = () => {},
}) {
  return (
    <button
      className={styles.menu_button}
      disabled={disabled}
      onClick={ e => onButtonClick() }
    >
      <Item icon={icon} label={label} />
    </button>
  );
}
