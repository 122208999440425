import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';

import styles from './evaluator.module.css';
import EvaluatorForm from './components/form';
import Results from './components/results';
import evaluatorReducer from './redux/reducer';
import evaluatorSaga from './redux/saga';

// init redux
const reducer = combineReducers({
  evaluator: evaluatorReducer,
});
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  reducer,
  applyMiddleware(sagaMiddleware),
);
sagaMiddleware.run(evaluatorSaga);

/**
 * Evaluator component.
 */
export default function Evaluator() {
  return (
    <Provider store={store}>
      <div className={`${styles.evaluator} container`}>
        <h1 className={styles.title}>Maschine bewerten</h1>

        <div className={styles.separator}></div>

        <div style={{ marginBottom: '2.5rem' }}>
          <EvaluatorForm />
        </div>

        <div className={styles.separator}></div>

        <div>
          <Results />
        </div>
      </div>
    </Provider>
  );
}
