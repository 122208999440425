import React from 'react';

import '../../../components/benefit/benefit-line.css';
import '../../../components/benefit/benefit.css';
import target from './target.svg';
import shield from './shield.svg';
import handshake from './handshake.svg';

export default function BenefitLine() {
  return (
    <div className="benefit-line">
      <div className="benefit-line__container container">
        <div className="benefit-line__item benefit benefit_light">
          <div className="benefit__image-block">
            <img className="benefit__image" src={target} alt="Target" />
          </div>
          <div className="benefit__text-block">
            <span className="benefit__text">
              Keine langwierige und beratungsintensive
            </span>
            {' '}
            <span className="benefit__text benefit__text_accent">
              Käufersuche.
            </span>
          </div>
        </div>

        <div className="benefit-line__separator"></div>

        <div className="benefit-line__item benefit benefit_light">
          <div className="benefit__image-block">
            <img className="benefit__image" src={shield} alt="Shield" />
          </div>
          <div className="benefit__text-block">
            <span className="benefit__text benefit__text_accent">
              Keine Wertminderung 
            </span>
            {' '}
            <span className="benefit__text">
              durch zu lange Standzeiten.
            </span>
          </div>
        </div>

        <div className="benefit-line__separator"></div>

        <div className="benefit-line__item benefit benefit_light">
          <div className="benefit__image-block">
            <img className="benefit__image" src={handshake} alt="Handshake" />
          </div>
          <div className="benefit__text-block">
            <span className="benefit__text">
              Keine Kapitalbindung sondern
            </span>
            {' '}
            <span className="benefit__text benefit__text_accent">
              direkte Abverkäufe.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
