import { connect } from 'react-redux';
import PlotView from './view';

function stp(s) {
  return {
    plotData: s.evaluator.plotData,
  };
}

function dtp(d) {
  return {};
}

const Plot = connect(
  stp,
  dtp,
)(PlotView);

export default Plot;
