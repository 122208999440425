import React from 'react';
import { navigate } from '@reach/router';

import styles from './menu.module.css';
import MenuButton from './item/button';

import user from './images/user.svg';
import gear from './images/gear.svg';
import logout from './images/logout.svg';


export default function ToolsMenu() {
  const cookieName = '_hdlr1';  // also in Login and Dashboard (next)
  
  function handleLogoutClick() {
    // removing cookie
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    navigate('/login');
  }

  return (
    <ul className={styles.menu} style={{ paddingBottom: '1.5rem' }}>
      <li className={styles.menu_item}>
        <MenuButton
          icon={user}
          label="Mein Account"
          disabled
        />
      </li>
      <li className={styles.menu_item}>
        <MenuButton
          icon={gear}
          label="Einstellungen"
          disabled
        />
      </li>
      <li className={styles.menu_item}>
        <MenuButton
          icon={logout}
          label="Ausloggen"
          onButtonClick={handleLogoutClick}
        />
      </li>
    </ul>
  );
}
