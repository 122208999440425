import StepId from '../../step-id.enum';
import alignCategoryWithType from '../helpers/align-category-with-type';

export default function makeGetModels(categories, brands) {
  return function(values) {
    const { categoryId, machineTypeId } =
      alignCategoryWithType(categories, values);

    const brandId = values
      .find(x => x.stepId === StepId.MANUFACTURER)
      .value
      .id;
    const brand = brands.find(x => x.id === brandId);

    let modelGroup;
    if (machineTypeId) {
      modelGroup = brand
        .modelGroups
        .find(x => x.machineTypeId === machineTypeId);
    } else {
      modelGroup = brand
        .modelGroups
        .find(x => x.categoryId === categoryId);
    }
    return modelGroup ? modelGroup.models : [];
  }
}
