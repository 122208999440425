import React from 'react';
import { Link } from '@reach/router';
import { Drawer } from 'antd';

import styles from './custom-drawer.module.css';
import close from './close.svg';
import logo from './logo.svg';


export default function CustomDrawer({ children, visible, onClose }) {
  return (
    <Drawer
      placement="left"
      closable={true}
      headerStyle={{ display: 'none' }}
      bodyStyle={{ padding: 0 }}
      width={null}
      visible={visible}
      onClose={onClose}
    >
      <div className={styles.header}>
        <button className={styles.close_button} onClick={ e => onClose() }>
          <img
            src={close}
            alt="Close"
            style={{ height: '1.5rem' }}
          />
        </button>
        <Link to="/">
          <img
            src={logo}
            alt="Logo"
            style={{ height: '1.5rem', marginLeft: '.75rem' }}
          />
        </Link>
      </div>
      { children }
    </Drawer>
  );
}
