import traktor from './images/traktor.svg';
import loader from './images/loader.svg';
import sowing from './images/sowing-planting.svg';
import fertilization from './images/fertilization.svg';
import combine from './images/combine.svg';

// tillage
import plow from './images/tillage/plow.svg';
import cultivator from './images/tillage/cultivator.svg';
import circleHarrow from './images/tillage/circle-harrow.svg';
import discHarrow from './images/tillage/disc-harrow.svg';
import millingMachine from './images/tillage/milling-machine.svg';
import mulcher from './images/tillage/mulcher.svg';

import slurry from './images/slurry.svg';

// transport
import pusher from './images/transport/pusher.svg';
import dolly from './images/transport/dolly.svg';
import hookLift from './images/transport/hook-lift.svg';
import tipper from './images/transport/tipper.svg';
import threeWayTipper from './images/transport/3-way-tipper.svg';
import wellsTipper from './images/transport/wells-tipper.svg';
import platformTrailer from './images/transport/platform-trailer.svg';
import silage from './images/transport/silage.svg';

// harvesting
import potatoHarvester from './images/harvesting/potato-harvester.svg';
//import combineType from './images/harvesting/combine.svg';  // clone of "combine"
import beetHarvester from './images/harvesting/beet-harvester.svg';
import forage from './images/forage.svg';
//import forageHarvester from './images/harvesting/forage-harvester.svg';

// grassland
import rake from './images/grassland/rake.svg';
import zeter from './images/grassland/zeter.svg';
import sliceMower from './images/grassland/slice-mower.svg';
import grasslandWeeder from './images/grassland/grassland-weeder.svg';
import roundBaler from './images/grassland/round-baler.svg';

import feeding from './images/feeding.svg';
import irrigation from './images/irrigation.svg';

/**
 * Production categories & machine types data.
 */
const categories = [
  {
    id: 'cat.1.tractor',
    label: 'Traktor',
    image: traktor,
  },
  {
    id: 'cat.2.loader',
    label: 'Radlader',
    image: loader,
  },
  {
    id: 'cat.3.sowing-planting',
    label: 'Sä- und Pflanztechnik',
    image: sowing,
  },
  {
    id: 'cat.4.fertilization',
    label: 'Dünger und Pflanzenschutz',
    image: fertilization,
  },
  {
    id: 'cat.5.combine',
    label: 'Mähdrescher',
    image: combine,
  },
  {
    id: 'cat.6.tillage',
    label: 'Bodenbearbeitung',
    image: plow,
    machineTypes: [
      {
        id: 'typ.6.1.plow',
        label: 'Pflug',
        image: plow,
      },
      {
        id: 'typ.6.2.cultivator',
        label: 'Grubber',
        image: cultivator,
      },
      {
        id: 'typ.6.3.harrow',
        label: 'Kreislegge',
        image: circleHarrow,
      },
      {
        id: 'typ.6.4.disc-harrow',
        label: 'Scheibenegge',
        image: discHarrow,
      },
      {
        id: 'typ.6.5.milling-machine',
        label: 'Fräse',
        image: millingMachine,
      },
      {
        id: 'typ.6.6.mulcher',
        label: 'Mulcher',
        image: mulcher,
      },
    ],
  },
  {
    id: 'cat.7.slurry',
    label: 'Gülletechnik',
    image: slurry,
  },
  {
    id: 'cat.8.transport',
    label: 'Transporttechnik',
    image: pusher,
    machineTypes: [
      {
        id: 'typ.8.1.pusher',
        label: 'Abschiebewagen',
        image: pusher,
      },
      {
        id: 'typ.8.2.dolly',
        label: 'Dolly',
        image: dolly,
      },
      {
        id: 'typ.8.3.hook-lift',
        label: 'Hakenliftanhänger',
        image: hookLift,
      },
      {
        id: 'typ.8.4.three-way-tipper',
        label: 'Dreiseitenkipper',
        image: threeWayTipper,
      },
      {
        id: 'typ.8.5.tipper',
        label: 'Kipper',
        image: tipper,
      },
      {
        id: 'typ.8.6.wells-tipper',
        label: 'Muldenkipper',
        image: wellsTipper,
      },
      {
        id: 'typ.8.7.platform-trailer',
        label: 'Plattformwagen',
        image: platformTrailer,
      },
      {
        id: 'typ.8.8.silage',
        label: 'Silierwagen',
        image: silage,
      },
    ],
  },
  {
    id: 'cat.10.harvesting',
    label: 'Erntetechnik diverses',
    image: potatoHarvester,
    machineTypes: [
      {
        id: 'typ.10.1.beet-harvester',
        label: 'Rübenroder',
        image: beetHarvester,
      },
      {
        id: 'typ.10.2.forage-harvester',
        label: 'Feldhäcksler',
        image: forage,
      },
      {
        id: 'typ.10.3.potato-harvester',
        label: 'Kartoffelroder',
        image: potatoHarvester,
      },
    ],
  },
  {
    id: 'cat.11.grassland',
    label: 'Grünlandtechnik',
    image: rake,
    machineTypes: [
      {
        id: 'typ.11.1.rake',
        label: 'Schwader',
        image: rake,
      },
      {
        id: 'typ.11.2.zeter',
        label: 'Zeter',
        image: zeter,
      },
      {
        id: 'typ.11.3.slice-mower',
        label: 'Scheibenmähwerk',
        image: sliceMower,
      },
      {
        id: 'typ.11.4.grassland-weeder',
        label: 'Grünlandstriegel',
        image: grasslandWeeder,
      },
      {
        id: 'typ.11.5.round-baler',
        label: 'Rundballenpresse',
        image: roundBaler,
      },
    ],
  },
  {
    id: 'cat.12.feeding',
    label: 'Fütterungstechnik',
    image: feeding,
  },
  {
    id: 'cat.13.irrigation',
    label: 'Beregnungstechnik',
    image: irrigation,
  },

];

export default categories;
