import React from 'react';

import './sign-up-page.css';
import SignUp from '../../components/sign-up';

export default function SignUpPage() {
  function handleFormSubmit() {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }

  return (
    <div className="sign-up-page">
      <div className="container">
        <div className="sign-up-page__container">
          <SignUp onFormSubmit={handleFormSubmit} />
        </div>
      </div>
    </div>
  );
}
