import StepId from '../step-id.enum';
import makeGetMachineTypeAvailable
  from '../getters/machine-types/make-get-available';
import makeGetAddOns from '../getters/add-ons/make-get-add-ons';

import categories from './categories';
import addOns from './add-ons/add-ons.json';  // JSON-based

function getCurrentCategoryId(values) {
  return values
    .find(x => x.stepId === StepId.CATEGORY)
    .value
    .id;
}

/**
 * List of categories to track hectares per year 
 * rather than working hours.
 */
const hectareCategoryIds = [
  'cat.6.tillage',
];

/**
 * Production step availability getters map.
 */
const availabilities = [
  {
    stepId: StepId.MACHINE_TYPE,
    getAvailable: makeGetMachineTypeAvailable(categories),
  },
  {
    stepId: StepId.HOUR,
    getAvailable: values => {
      const categoryId = getCurrentCategoryId(values);
      return !hectareCategoryIds.includes(categoryId);
    },
  },
  {
    stepId: StepId.HECTARE,
    getAvailable: values => {
      const categoryId = getCurrentCategoryId(values);
      return hectareCategoryIds.includes(categoryId);
    },
  },
  {
    stepId: StepId.SIGN_UP,
    getAvailable: values => {
      const forecastRecord = values
        .find(x => x.stepId === StepId.FORECAST);
      return !!forecastRecord;
    },
  },
  {
    stepId: StepId.ADD_ONS,
    getAvailable: values => {
      const _addOns = makeGetAddOns(categories, addOns)(values);
      return _addOns.length > 0;
    },
  }
];

export default availabilities;
