import { connect } from 'react-redux';

import ForecastAutomaticView from './view';
import PredictorAction from '../../../modules/predictor/action-type.enum';
import StepId from '../../../data/step-id.enum';

function mapStateToProps(state) {
  return {
    values: state.predictor.values,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onProceed: value => dispatch({
      type: PredictorAction.STEP_SELECT,
      payload: {
        stepId: StepId.FORECAST,
        value,
      },
    }),
    onRestart: () => dispatch({ type: PredictorAction.RESTART }),
  };
}

const ForecastAutomatic = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForecastAutomaticView);

export default ForecastAutomatic;
