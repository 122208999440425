import React from 'react';

import './benefit-grid.css';
import '../../../components/benefit/benefit.css';

import handshake from './images/handshake.svg';
import saveMoney from './images/save-money.svg';
import twentyFourSeven from './images/twenty-four-seven.svg';

import shield from './images/shield.svg';
import target from './images/target.svg';
import tractor from './images/tractor.svg';

import coinPlant from './images/coin-plant.svg';
import moneyPig from './images/money-pig.svg';
import investment from './images/investment.svg';


/**
 * Benefit Grid component.
 */
export default function BenefitGrid() {
  return (
    <div style={{ position: 'relative', backgroundColor: 'white' }}>
      <div className="benefit-grid container">
        <h2 className="benefit-grid__heading">
          Ihre Vorteile auf einem Blick!
        </h2>

        <div className="row">
          <div className="benefit-grid__item col-12 col-md-6 col-lg-4">
            <div className="benefit benefit_dark benefit_dense">
              <div className="benefit__image-block">
                <img className="benefit__image" src={handshake} alt="Handshake" />
              </div>
              <div className="benefit__text-block">
                <span className="benefit__text">
                  Keine Kapitalbindung durch direkte Abverkäufe
                </span>
              </div>
            </div>
          </div>
          <div className="benefit-grid__item col-12 col-md-6 col-lg-4">
            <div className="benefit benefit_dark benefit_dense">
              <div className="benefit__image-block">
                <img className="benefit__image" src={saveMoney} alt="Save Money" />
              </div>
              <div className="benefit__text-block">
                <span className="benefit__text">
                  Keine langwierige und beratungsintensive Käufersuche
                </span>
              </div>
            </div>
          </div>
          <div className="benefit-grid__item col-12 col-md-6 col-lg-4">
            <div className="benefit benefit_dark benefit_dense">
              <div className="benefit__image-block">
                <img className="benefit__image" src={twentyFourSeven} alt="24/7" />
              </div>
              <div className="benefit__text-block">
                <span className="benefit__text">
                  Rund um die Uhr verfügbar
                </span>
              </div>
            </div>
          </div>

          <div className="benefit-grid__item col-12 col-md-6 col-lg-4">
            <div className="benefit benefit_dark benefit_dense">
              <div className="benefit__image-block">
                <img className="benefit__image" src={shield} alt="Shield" />
              </div>
              <div className="benefit__text-block">
                <span className="benefit__text">
                  Kein An- &amp; Abverkaufsrisiko
                </span>
              </div>
            </div>
          </div>
          <div className="benefit-grid__item col-12 col-md-6 col-lg-4">
            <div className="benefit benefit_dark benefit_dense">
              <div className="benefit__image-block">
                <img className="benefit__image" src={target} alt="Target" />
              </div>
              <div className="benefit__text-block">
                <span className="benefit__text">
                  Keine Wertminderung durch zu lange Standzeiten
                </span>
              </div>
            </div>
          </div>
          <div className="benefit-grid__item col-12 col-md-6 col-lg-4">
            <div className="benefit benefit_dark benefit_dense">
              <div className="benefit__image-block">
                <img className="benefit__image" src={tractor} alt="Tractor" />
              </div>
              <div className="benefit__text-block">
                <span className="benefit__text">
                  Nur durch Händler geprüfte Landmaschinen inseriert
                </span>
              </div>
            </div>
          </div>

          <div className="benefit-grid__item col-12 col-md-6 col-lg-4">
            <div className="benefit benefit_dark benefit_dense">
              <div className="benefit__image-block">
                <img className="benefit__image" src={coinPlant} alt="Coin Plant" />
              </div>
              <div className="benefit__text-block">
                <span className="benefit__text">
                  Kosten- &amp; Zeitersparnis
                </span>
              </div>
            </div>
          </div>
          <div className="benefit-grid__item col-12 col-md-6 col-lg-4">
            <div className="benefit benefit_dark benefit_dense">
              <div className="benefit__image-block">
                <img className="benefit__image" src={moneyPig} alt="Money Pig" />
              </div>
              <div className="benefit__text-block">
                <span className="benefit__text">
                  Einfache zustandsabhängige Preisfindung
                </span>
              </div>
            </div>
          </div>
          <div className="benefit-grid__item col-12 col-md-6 col-lg-4">
            <div className="benefit benefit_dark benefit_dense">
              <div className="benefit__image-block">
                <img className="benefit__image" src={investment} alt="Investment" />
              </div>
              <div className="benefit__text-block">
                <span className="benefit__text">
                  Mehr Verhandlungs&shy;sicherheit
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
