import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';

import './verify.css';
import Input from '../../components/input';


export default function Login() {
  const [incorrect, setIncorrect] = useState(false);

  const cookieName = '_hdlr1';  // also in Dashboard Index and Tools Menu

  const cookieParts = document.cookie.split(';');
  const token = cookieParts.find(x => {
    const name = x.split('=')[0];
    return name.trim() === cookieName;
  });
  
  useEffect(() => {
    if (token) {
      navigate('/dashboard');
    }
  }, [token]);

  function handleSubmit(e) {
    e.preventDefault();
    const password = e.target.password.value;
    if (password !== 'Bewertung') {
      setIncorrect(true);
    } else {
      // setting cookie that expires in two weeks
      const expiryDays = 14;
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + expiryDays);
      document.cookie = `${cookieName}=2xx3v8yb6; expires=${expiryDate.toUTCString()}`;
      navigate('/dashboard');
    }
  }

  function renderIncorrect() {
    return incorrect &&
      <div className="verify__incorrect">
        Das Passwort ist inkorrekt.
      </div>;
  }

  function renderBody() {
    return (
      <div className="verify container">
        <h1 className="verify__heading">Zugang zum Bewertungstool</h1>

        <p className="verify__subheading">
          Bitte geben Sie das Passwort ein, dass Sie per E-Mail 
          von uns zugesendet bekommen haben.
        </p>

        <form onSubmit={handleSubmit}>
          <div className="verify__input-wrapper">
            <Input
              name="password"
              type="password"
              placeholder="Passwort"
              prefix={<i className="icon-padlock"></i>}
              fullSize
              required
            />
            { renderIncorrect() }
          </div>

          <div className="verify__submit-wrapper">
            <button type="submit" className="hdlr1-button">
              Einloggen
            </button>
          </div>
        </form>

        <p className="verify__hint">
          <span className="verify__text-accent">Sie haben kein Passwort?</span> Schreiben 
          Sie uns jetzt eine E-Mail und informieren 
          Sie sich, wie man heutzutage Maschinen variantenspezifisch bewertet.
        </p>
      </div>
    );
  }

  return token ? null : renderBody();
}
