import React from 'react';
import { Link } from '@reach/router';

import './laptop.css';
import laptop from './laptop-1000px.png';


export default function Laptop() {
  return (
    <div className="laptop">
      <div style={{ position: 'relative' }}>
        <div className="laptop__image-wrapper">
          <img
            className="laptop__image"
            src={laptop}
            alt="Laptop"
          />
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-9 col-md-8 col-lg-6">
              <h2 className="laptop__heading">
                Händler1.de
              </h2>

              <p className="laptop__text">
                Werden Sie Teil unserer innovativen Remarketing-Plattform 
                für gebrauchte Land- &amp; Baumaschinen!
              </p>

              <p className="laptop__text">
                Vernetzen Sie sich mit anderen Händlern und tauschen Sie 
                untereinander Fachkenntnisse, Maschinendaten, Bewertungen und 
                weitere Informationen aus.
              </p>

              <p className="laptop__text">
                Unsere Devise lautet: Besser miteinander, statt gegeneinander!
              </p>

              <div>
                <Link className="hdlr1-button" to="/sign-up">
                  Jetzt Kostenlos registrieren
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
