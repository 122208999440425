import React from 'react';
import { Modal } from 'antd';

import './sign-up-modal.css';
import SignUp from '.';


export default function SignUpModal(props) {
  return (
    <Modal
      title={null}
      footer={null}
      className="sign-up-modal"
      width={null}
      centered={true}
      destroyOnClose={true}
      maskStyle={{ backgroundColor: 'rgba(250, 250, 250, 0.95)' }}
      closeIcon={<i className="icon-close"></i>}
      {...props}
    >
      <SignUp />
    </Modal>
  );
}
