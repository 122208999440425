import StepId from '../../step-id.enum';
import { getValueForStep } from '../utils';

//const backendUrl = 'http://localhost:3001/forecast';  // env, HARDCODE
const backendUrl =
  //'https://ce4r91cyj4.execute-api.eu-central-1.amazonaws.com/Prod/predict'; // HARDCODE
  'https://6esgx2ggn7.execute-api.eu-central-1.amazonaws.com/Prod/predict'; // HARDCODE


/**
 * Forecast content getter that fetches 
 * forecast data from **real backend**.
 * @param {*} values 
 */
export default async function getForecastContent(values) {
  const brand = getValueForStep(values, StepId.BRAND);
  const model = getValueForStep(values, StepId.MODEL);
  const year = getValueForStep(values, StepId.YEAR);
  const powerId = getValueForStep(values, StepId.POWER, 'id');
  const powerLower = powerId.split(' - ')[0];
  //console.log({ powerLower });

  let hourLower = 0;
  const hourRecord = values
    .find(x => x.stepId === StepId.HOUR);
  if (hourRecord) {
    hourLower = hourRecord.value.id.split(' - ')[0];
  }
  //console.log({ hourLower });

  const bodyObj = {
    brand,
    model,
    year,
    PS: +powerLower,
    engine_hours: +hourLower,
  };

  const addOnRecord = values.find(x => x.stepId === StepId.ADD_ONS);
  if (addOnRecord) {
    const addOns = addOnRecord.value;
    addOns.map(x => bodyObj[x.id] = true);
  }

  const response = await fetch(backendUrl, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyObj),
  });
  const data = await response.json();
  const { lower, upper } = data;

  return {
    brand,
    model,
    year,
    priceFrom: lower,
    priceTill: upper,
  };
}
