import React from 'react';

import styles from './back.module.css';


/**
 * TODO: make it button
 */
export default function Back({ onBackClick = () => {} }) {
  return (
    <div className={styles.back} onClick={ e => onBackClick() }>
      <button className="dshb-button dshb-button_round">
        <i className="fas fa-arrow-left"></i>
      </button>
      <span className={styles.text}>
        Zurück
      </span>
    </div>
  );
}
