import React from 'react';

import './error-screen.css';
import '../styles/step-frame.css';
import '../styles/predictor.css';
import '../styles/custom-scrollbar.css';

export default function ErrorScreen() {
  return (
    <div data-testid="error-screen" className="step-frame">
      <div className="error-screen__content step-frame__content custom-scrollbar">
        <h1 className="error-screen__heading predictor__heading-1">
          Tut uns leid!
        </h1>

        <div className="error-screen__message-block">
          <div className="error-screen__message-large predictor__text predictor__text_large">
            Es ist ein interner Systemfehler aufgetreten.
          </div>
          <span className="error-screen__message predictor__text">
            Bitte versuchen Sie es später noch einmal
          </span>
          {' '}
          <span className="error-screen__message predictor__text">
            oder kontaktieren Sie uns über:
          </span>
        </div>

        <div className="error-screen__contact-block">
          <div>
            <a
              href="mailto:info@haendler1.de"
              className="error-screen__mail-link"
            >
              info@haendler1.de
            </a>
          </div>
          <div className="error-screen__contact-separator">|</div>
          <div className="error-screen__phone">
            <i className="fas fa-phone-alt"></i>
            {' '}
            <span>0151 112 075 39</span>
          </div>
        </div>

        <div>
          <a href="/" className="predictor__button predictor__button_accent">
            Zur Startseite
          </a>
        </div>
      </div>
    </div>
  );
}
