import React from 'react';

import Header from './header';
import BenefitLine from './benefit-line';
import Video from './video';
import Laptop from './laptop';
import Contact from '../../components/contact';

/**
 * Valuation (Bewertung) page component.
 */
export default function Valuation() {
  return (
    <div>
      <Header />
      <BenefitLine />
      <Video />
      <Laptop />
      <Contact />
    </div>
  );
}
