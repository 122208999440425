import alignCategoryWithType from '../helpers/align-category-with-type';

export default function makeGetAddOns(categories, addOns) {
  return function(values) {
    const { categoryId, machineTypeId } =
      alignCategoryWithType(categories, values);
    const addOnsByCategory = addOns.filter(x => {
      const { categoryIds } = x;
      return categoryIds &&
        !!x.categoryIds.find(y => y === categoryId);
    });
    const addOnsByType = addOns.filter(x => {
      const { machineTypeIds } = x;
      return machineTypeIds &&
        !!x.machineTypeIds.find(y => y === machineTypeId);
    });
    return [...addOnsByCategory, ...addOnsByType];
  }
}
