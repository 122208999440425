import React from 'react';

import styles from './price-block.module.css';

export default function PriceBlock({ title, hint, min, max, color }) {
  const strOptions = {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };
  const minDisplay = min.toLocaleString('de-DE', strOptions);
  const maxDisplay = max.toLocaleString('de-DE', strOptions);

  let blockStyle = {};
  if (color) {
    blockStyle = { color };
  }
  
  return (
    <div className={styles.block} style={blockStyle}>
      <div className={styles.title}>
        { title }
      </div>
      <div className={styles.hint}>
        { hint }
      </div>
      <div className={styles.range}>
        { minDisplay } - { maxDisplay } Netto
      </div>
    </div>
  );
}
