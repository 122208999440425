import React, { useRef, useEffect } from 'react';


/**
 * Custom input component.
 */
export default function Input({
  prefix, required = false, fullSize = false, filled = false,
  controlStyle = {}, prefixStyle = {}, ...props
}) {
  const control = useRef(null);
  
  // toggling focus & blur styles
  useEffect(() => {
    const input = control.current.querySelector('input');
    function addFocusClass(e) {
      control.current.classList.add('hdlr1-form__control_filled_focus');
    }
    function removeFocusClass(e) {
      control.current.classList.remove('hdlr1-form__control_filled_focus');
    }
    input.addEventListener('focus', addFocusClass);
    input.addEventListener('blur', removeFocusClass);

    // memory cleanup
    return () => {
      input.removeEventListener('focus', addFocusClass);
      input.removeEventListener('blur', removeFocusClass);
    };
  });

  function renderPrefix() {
    if (prefix) {
      return (
        <div className="hdlr1-form__control-prefix" style={prefixStyle}>
          { prefix }
        </div>
      );
    }
  }

  let groupClassName = 'hdlr1-form__group';
  if (fullSize) {
    groupClassName += ' hdlr1-form__group_full-size';
  } else if (required) {
    groupClassName += ' hdlr1-form__group_required';
  }

  let controlClassName = 'hdlr1-form__control';
  if (filled) {
    controlClassName += ' hdlr1-form__control_filled';
  }

  return (
    <div className={groupClassName}>
      <div ref={control} className={controlClassName} style={controlStyle}>
        { renderPrefix() }
        <input
          className="hdlr1-form__control-input"
          required={required}
          {...props}
        />
      </div>
    </div>
  );
}
