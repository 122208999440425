import React from 'react';

import Banner from './banner';
import BenefitLine from './benefit-line';
import Offers from './offers';
import Video from './video';
import BenefitGrid from './benefit-grid';
import Network from './network';
import Testimonials from './testimonials';
import Contact from '../../components/contact';

export default function Homepage() {
  return (
    <div>
      <Banner />
      <BenefitLine />
      <Offers />
      <Video />
      <BenefitGrid />
      <Network />
      <Testimonials />
      <Contact />
    </div>
  );
}
