import React from 'react';

import '../../../components/benefit/benefit-line.css';
import '../../../components/benefit/benefit.css';

import shield from './shield.svg';
import moneyPig from './money-pig.svg';
import tools from './tools.svg';

export default function BenefitLine() {
  return (
    <div className="benefit-line">
      <div className="benefit-line__container container">
        <div className="benefit-line__item benefit benefit_light">
          <div className="benefit__image-block">
            <img className="benefit__image" src={shield} alt="Shield" />
          </div>
          <div className="benefit__text-block">
            <span className="benefit__text benefit__text_accent">
              Kostenlos testen
            </span>
            {' '}
            <span className="benefit__text">
              kein Risiko.
            </span>
          </div>
        </div>

        <div className="benefit-line__separator"></div>

        <div className="benefit-line__item benefit benefit_light">
          <div className="benefit__image-block">
            <img className="benefit__image" src={moneyPig} alt="Money Pig" />
          </div>
          <div className="benefit__text-block">
            <span className="benefit__text benefit__text_accent">
              Realistische
            </span>
            {' '}
            <span className="benefit__text">
              Bewertungen
            </span>
          </div>
        </div>

        <div className="benefit-line__separator"></div>

        <div className="benefit-line__item benefit benefit_light">
          <div className="benefit__image-block">
            <img className="benefit__image" src={tools} alt="Customer Support" />
          </div>
          <div className="benefit__text-block">
            <span className="benefit__text benefit__text_accent">
              Fachkompetenz
            </span>
            {' '}
            <span className="benefit__text">
              Bewertung durch Techniker
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
