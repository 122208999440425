import React from 'react';

import './marketplace-banner.css';
import bgSmall from './bg-small.jpg';
import bgLarge from './bg-large.jpg';

export default function Banner() {
  return (
    <div className="marketplace-banner">
      <img
        className="marketplace-banner__image d-lg-none"
        src={bgSmall}
        alt="Banner"
      />
      <img
        className="marketplace-banner__image d-none d-lg-inline"
        src={bgLarge}
        alt="Banner"
      />
      
      <div className="marketplace-banner__content container-fluid">
        Geprüfte Maschinen. Exklusiver Zugang - nur für Händler
      </div>
    </div>
  );
}
