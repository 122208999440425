import React from 'react';
import { Location } from '@reach/router';

import './navbar-logo.css';
import logo from './logo.svg';
import bewertung from './bewertung.png';
import marktplatz from './marktplatz.png';


function renderSecondaryImage(pathname) {
  if (pathname === '/valuation') {
    return (
      <img
        className="navbar-logo__secondary-image"
        src={bewertung}
        alt="Bewertung"
      />
    );
  }
  else if (pathname === '/marketplace') {
    return (
      <img
        className="navbar-logo__secondary-image"
        src={marktplatz}
        alt="Marktplatz"
      />
    );
  }
}


/**
 * Navbar logo component. 
 */
export default function Logo() {
  return (
    <Location>
      {
        function({ location }) {
          return (
            <div className="navbar-logo">
              <img
                className="navbar-logo__image"
                src={logo}
                alt="Logo"
              />
              { renderSecondaryImage(location.pathname) }
            </div>
          );
        }
      }
    </Location>
  );
}
