import React, { useState } from 'react';

import './contact.css';
import CustomCheckbox from '../custom-checkbox';
import Input from '../input';


export default function ContactForm({ onSend }) {
  const [agreeError, setAgreeError] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();

    if (!e.target.agree.checked) {
      setAgreeError(true);
    } else {
      const contactData = {
        name: e.target.name.value,
        company: e.target.company.value,
        email: e.target.email.value,
        message: e.target.message.value,
      }
      onSend(contactData);
    }
  }

  const agreeErrorDisplay = agreeError ? 'block' : 'none';

  return (
    <div>
      <h2 className="contact__heading">
        Schreiben Sie uns, wir&nbsp;helfen&nbsp;gerne!
      </h2>

      <form onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: 'auto' }}>
        <div>
          <Input
            prefix={<i className="icon-user"></i>}
            name="name"
            placeholder="Name"
            required
          />
        </div>

        <div>
          <Input
            prefix={<i className="icon-user"></i>}
            name="company"
            placeholder="Firmenname"
            required
          />
        </div>

        <div>
          <Input
            prefix={<i className="icon-envelope"></i>}
            type="email"
            name="email"
            placeholder="E-mail"
            required
          />
        </div>

        <div
          className="hdlr1-form__group hdlr1-form__group_required"
          style={{ marginBottom: '2rem' }}
        >
          <div className="hdlr1-form__control">
            <div
              className="hdlr1-form__control-prefix"
              style={{ alignItems: 'flex-start', paddingTop: '.5rem' }}
            >
              <i className="icon-envelope"></i>
            </div>
            <textarea
              className="hdlr1-form__control-input"
              name="message"
              placeholder="Nachricht"
              rows="7"
              required
            ></textarea>
          </div>
        </div>

        <div style={{ marginBottom: '1.5rem' }}>
          <div style={{  textAlign: 'left' }}>
            <CustomCheckbox name="agree" onChange={ e => setAgreeError(false) }>
              <span>
                Ich akzeptiere 
                die <a className="contact__link" href="/privacy-policy" target="_blank">
                  Datenschutzbestimmungen
                </a> sowie 
                die <a className="contact__link" href="/terms-of-service" target="_blank">
                  AGB*
                </a>
              </span>
            </CustomCheckbox>

            <div
              style={{
                display: agreeErrorDisplay,
                marginLeft: '1.5rem',
                color: '#c71e1e',
              }}
            >
              Bitte akzeptieren Sie die Bedingungen.
            </div>
          </div>
        </div>

        <div>
          <button type="submit" className="hdlr1-button">
            Senden
          </button>
        </div>
      </form>
    </div>
  );
}
