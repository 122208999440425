import React, { useState } from 'react';
import { Link } from '@reach/router';

import './marketplace-alert.css';
import close from './close.svg';

export default function Alert() {
  const [alertStyle, setAlertStyle] = useState({});

  return (
    <div className="marketplace-alert" style={alertStyle}>
      <div className="marketplace-alert__text-block">
        <div style={{ fontWeight: 700 }}>
          Kein passendes Angebot dabei?
        </div>
        <div>Einfach Suchanfrage starten und auf Angebote warten.</div>
      </div>
      <div>
        <Link className="marketplace-alert__link" to="/sign-up">
          Hier Klicken
        </Link>
      </div>

      <button
        className="marketplace-alert__close-button"
        onClick={ e => setAlertStyle({ display: 'none' })}
      >
        <img src={close} alt="Close" />
      </button>
    </div>
  );
}
