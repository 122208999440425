import StepId from '../step-id.enum';
import makeGetMachineTypes from '../getters/machine-types/make-get-machine-types';
import makeGetBrands from '../getters/brands/make-get-brands';
import makeGetModels from '../getters/models/make-get-models';
import makeGetAddOns from '../getters/add-ons/make-get-add-ons';

import categories from './categories';
//import brands from './brands';            // JS-based
import allBrands from './brands/json-merged';  // JSON-based
import powerOptions from './power';
import years from './years';
import hours from './hours';
import hectares from './hectares';
import conditions from './conditions';
import tiresConditions from './conditions/tires';
//import addOns from './add-ons';   // JS-based
import addOns from './add-ons/add-ons.json';  // JSON-based
import getForecastContent from './forecast/get-forecast-content';     // prod
//import getForecastContent from './forecast/get-forecast-content-test';  // test
import getFinishContent from './finish/get-finish-content';     // prod
//import getFinishContent from './finish/get-finish-content-test';  // test

// sort compare function
function compare(a, b) {
  if (a.label > b.label) return 1;
  if (a.label <= b.label) return -1;
  return 0;
}

/**
 * Production content getters.
 */
const contentGetters = [
  {
    stepId: StepId.CATEGORY,
    getContent: values => {
      return categories;
    }
  },
  {
    stepId: StepId.MACHINE_TYPE,
    getContent: makeGetMachineTypes(categories),
  },
  {
    stepId: StepId.MANUFACTURER,
    getContent: values => {
      const brands = makeGetBrands(categories, allBrands)(values);
      return brands.sort(compare);
    },
  },
  {
    stepId: StepId.MODEL,
    getContent: values => {
      const models = makeGetModels(categories, allBrands)(values);
      return models.sort(compare);
    },
  },
  {
    stepId: StepId.POWER,
    getContent: values => powerOptions,
  },
  {
    stepId: StepId.YEAR,
    getContent: values => {
      return years;
    },
  },
  {
    stepId: StepId.HOUR,
    getContent: values => {
      return hours;
    },
  },
  {
    stepId: StepId.HECTARE,
    getContent: values => {
      return hectares;
    },
  },
  {
    stepId: StepId.CONDITION,
    getContent: values => {
      return conditions;
    },
  },
  {
    stepId: StepId.TIRES_CONDITION,
    getContent: values => tiresConditions,
  },
  {
    stepId: StepId.ADD_ONS,
    getContent: makeGetAddOns(categories, addOns),
  },
  {
    stepId: StepId.FORECAST,
    getContent: getForecastContent,
  },
  {
    stepId: StepId.FINISH,
    getContent: getFinishContent,
  },
];

export default contentGetters;
