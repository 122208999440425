import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import './index.css';
import subAppReducer from './reducer';
import predictorSaga from './modules/predictor/saga';
import Container from './components/container';

import steps from './data/steps';
//import contentGetters from './data/fixtures/content-getters';   // test
//import availabilities from './data/fixtures/availabilities';    // test
import contentGetters from './data/production/content-getters';   // prod
import availabilities from './data/production/availabilities';    // prod

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  subAppReducer,
  applyMiddleware(sagaMiddleware),
);
sagaMiddleware.run(predictorSaga, { steps, contentGetters, availabilities });

/**
 * Predictor sub-app.
 */
export default function Predictor() {
  return (
    <Provider store={store}>
      <Container />
    </Provider>
  );
}
