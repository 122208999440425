import { connect } from 'react-redux';

import ForecastView from './view';

function mapStateToProps(state) {
  return {
    content: state.predictor.content,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

const Forecast = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForecastView);

export default Forecast;
