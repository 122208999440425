import { connect } from 'react-redux';

import FormView from './view';
import EvaluatorAction from '../../redux/action-type.enum';

function stp(s) {
  return {};
}

function dtp(d) {
  return {
    onSubmit: values => d({
      type: EvaluatorAction.FORM_SUBMIT,
      payload: { values },
    }),
  };
}

const EvaluatorForm = connect(
  stp,
  dtp,
)(FormView);

export default EvaluatorForm;
