import React, { useState } from 'react';

import styles from './form.module.css';
import '../../../styles/custom-scrollbar.css';
import tractors0 from '../../../../data/tractors.link';
import addOns0 from '../../../../data/add-ons.link';
import Select from '../select';
import Checkbox from '../checkbox';

/**
 * Evaluator form presentational component.
 */
export default function FormView({ onSubmit }) {
  const [brand, setBrand] = useState();
  const [model, setModel] = useState();

  const sortByLabel = (a, b) => (a.label > b.label) ? 1 : -1;
  // sorting tractors by brand
  const tractors = tractors0.sort(sortByLabel);
  // sorting add-ons by label
  const addOns = addOns0.sort(sortByLabel);

  // handling submit
  function handleSubmit(e) {
    e.preventDefault();
    // serializing form
    const values = {};
    for (const prop of e.target) {
      const { name, value, checked } = prop;
      if (name) {
        if (value) {
          values[name] = prop.value;
        } else if (checked) {
          values[name] = true;
        }
      }
    }
    onSubmit(values);
  }

  // add-ons
  function renderAddOns() {
    return addOns.map((x, i) => (
      <div key={i} style={{ marginBottom: '.35rem' }}>
        <Checkbox name={x.id}>
          <span className={styles.checkbox_label}>
            { x.label }
          </span>
        </Checkbox>
      </div>
    ));
  }

  // brands
  function renderBrandOptions() {
    return tractors.map((x, i) => {
      const { label } = x;
      return (
        <option key={i} value={label}>
          { label }
        </option>
      );
    });
  }
  function handleBrandChange(e) {
    setBrand(e.target.value);
    setModel('');
  }

  // models
  function renderModelOptions() {
    const _brand = tractors.find(x => x.label === brand);
    if (!_brand) {
      return null;
    }
    const { models } = _brand.modelGroups[0];
    models.sort(sortByLabel); // sorting models by label
    return models.map((x, i) => {
      return (
        <option key={i} value={x.label}>
          { x.label }
        </option>
      );
    });
  }
  function handleModelChange(e) {
    setModel(e.target.value);
  }

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div>
        <div className={styles.group}>
          <label className={styles.label}>Maschinentyp:</label>
          <div className={styles.control_wrapper}>
            <Select
              name="machine_type"
              required
            >
              <option value="tractor">Traktor</option>
            </Select>
          </div>
        </div>

        <div className={styles.group}>
          <label className={styles.label}>Hersteller:</label>
          <div className={styles.control_wrapper}>
            <Select
              name="brand"
              value={brand}
              onChange={handleBrandChange}
              required
            >
              <option value="" hidden>-- Auswählen --</option>
              { renderBrandOptions() }
            </Select>
          </div>
        </div>

        <div className={styles.group}>
          <label className={styles.label}>Modell:</label>
          <div className={styles.control_wrapper}>
            <Select
              name="model"
              value={model}
              onChange={handleModelChange}
              required
            >
              <option value="" hidden>-- Auswählen --</option>
              { renderModelOptions() }
            </Select>
          </div>
        </div>
      </div>

      <div>
        <div className={styles.group}>
          <label className={styles.label}>Baujahr:</label>
          <input
            name="year"
            type="number"
            placeholder="Zahl eingeben"
            className={`${styles.control} dshb-input`}
            required
            min={1990}
            max={(new Date()).getFullYear()}
          />
        </div>
        <div className={styles.group}>
          <label className={styles.label}>Leistung in PS:</label>
          <input
            name="power"
            type="number"
            placeholder="Zahl eingeben"
            className={`${styles.control} dshb-input`}
            required
            min={0}
          />
        </div>
        <div className={styles.group}>
          <label className={styles.label}>Betriebsstunden:</label>
          <input
            name="hours"
            type="number"
            placeholder="Zahl eingeben"
            className={`${styles.control} dshb-input`}
            required
            min={0}
          />
        </div>
      </div>

      <div>
        <p className={styles.checkbox_group_label}>Zusatzausstattung:</p>
        <div className={`${styles.checkbox_group} custom-scrollbar`}>
          { renderAddOns() }
        </div>
      </div>

      <div className={styles.submit_wrapper}>
        <button type="submit" className="dshb-button">
          Bewertung starten
        </button>
      </div>
    </form>
  );
}
