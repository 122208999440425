import React from 'react';

import styles from './menu.module.css';
import MenuButton from './item/button';

import home from './images/home.svg';
import info from './images/info.svg';
import email from './images/email.svg';
import tractor from './images/tractor.svg';
import star from './images/star.svg';
import network from './images/network.svg';
import group from './images/group.svg';


export default function MainMenu() {
  return (
    <ul className={styles.menu}>
      <li className={styles.menu_item}>
        <MenuButton
          icon={home}
          label="Startseite"
          disabled
        />
      </li>
      <li className={styles.menu_item}>
        <MenuButton
          icon={info}
          label="Neuigkeiten"
          disabled
        />
      </li>
      <li className={styles.menu_item}>
        <MenuButton
          icon={email}
          label="Nachrichten"
          disabled
        />
      </li>
      <li className={styles.menu_item}>
        <MenuButton
          icon={tractor}
          label="Meine Inserate"
          disabled
        />
      </li>
      <li className={styles.menu_item}>
        <MenuButton
          icon={star}
          label="Meine Merkliste"
          disabled
        />
      </li>
      <li className={styles.menu_item}>
        <MenuButton
          icon={network}
          label="Mein Netzwerk"
          disabled
        />
      </li>
      <li className={styles.menu_item}>
        <MenuButton
          icon={group}
          label="Händlerverzeichnis"
          disabled
        />
      </li>
    </ul>
  );
}
