import React from 'react';
import CookieBar from '.';


/**
 * Cookie bar wrapper for routing.
 */
export default function CookieBarWrapper({ children }) {
  return (
    <div>
      { children }
      <CookieBar />
    </div>
  );
}
