import React, { useEffect } from 'react';
import { useLocation } from '@reach/router';

import styles from './main.module.css';
import Top from '../top';
import Navbar from '../navbar';
import Footer from '../footer';


/**
 * Main component.
 * Scrolls the window to top on route change.
 */
export default function Main({ children }) {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [pathname]);

  // scrolling to element from route hash
  useEffect(() => {
    if (hash) {
      // setting timeout so heavy dynamic elements (e.g. carousel) can initialize
      setTimeout(() => {
        const targetElement = document.querySelector(hash);
        const supportsNativeSmoothScroll =
          'scrollBehavior' in document.documentElement.style;
        if (targetElement) {
          const { offsetTop } = targetElement;
          if (supportsNativeSmoothScroll) {
            window.scrollTo({
              top: offsetTop - 80,
              left: 0,
              behavior: 'smooth',
            });
          } else {
            window.scrollTo(0, offsetTop - 80);
          }
        }
      }, 200);
    }
  }, [hash]);

  return (
    <div className={styles.layout}>
      <Top />
      <Navbar />

      <main className={styles.content}>
        { children }
      </main>

      <Footer />
    </div>
  );
}
