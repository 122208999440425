import React, { useState } from 'react';

import '../../../components/video/video.css';
import '../../../components/video/video-screen.css';
import tablet from './tablet.png';
import tabletSm from './tablet-sm.png';
import VideoModal from '../../../components/video/modal';

export default function Video() {
  const [isModalVisible, setModalVisible] = useState(false);

  return (
    <div id="how-it-works" className="video video_dark">
      <div className="video__block-small">
        <img
          className="video__image"
          src={tabletSm}
          alt="Video"
        />
        <div
          className="video__screen video__screen_small"
          onClick={ e => setModalVisible(true) }
        ></div>
      </div>
      <div className="video__block-large">
        <img
          className="video__image"
          src={tablet}
          alt="Video"
        />
        <div
          className="video__screen video__screen_large"
          onClick={ e => setModalVisible(true) }
        ></div>
      </div>

      <div className="video__text video__text_inverse container">
        <div className="row">
          <div className="col-12 col-lg-6 offset-lg-6 col-xl-5 offset-xl-7">
            <h2 className="video__heading">So funktioniert’s</h2>

            <p className="video__paragraph">
              Erfassen Sie alle Maschinendaten mit Hilfe unserer 
              Audit-App und erhalten Sie noch vor Ort eine erste 
              Marktpreisprognose.
            </p>

            <p className="video__paragraph">
              Laden Sie anschließend die Daten mit 360° Bild, 
              Mängelliste und Historie direkt in unser B2B-Portal 
              und geben Sie diese öffentlich, Ihrem Netzwerk oder 
              zur internen Bearbeitung und Bewertung frei!
            </p>

            <p className="video__paragraph">
              Sofern Sie die Maschine öffentlich, oder in Ihrem 
              Netzwerk teilen, können Sie neben Bewertungen auch 
              verbindliche Kaufangebote erhalten, auf dessen Basis 
              Sie den Ankauf mit Ihrem Kunden abwickeln können.
            </p>

            <p className="video__paragraph">
              So ersparen Sie sich Zeit und Geld, umgehen das 
              An- &amp; Abverkaufsrisiko und können sich voll und 
              ganz auf das Neumaschinengeschäft konzentrieren!
            </p>

            <div style={{ paddingTop: '1rem' }}>
              <button
                className="hdlr1-button"
                onClick={ () => setModalVisible(true) }
              >
                Mehr Informationen
              </button>
            </div>
          </div>
        </div>
      </div>

      <VideoModal
        visible={isModalVisible}
        onClose={() => setModalVisible(false)}
      />
    </div>
  );
}
