import React from 'react';

import '../../../components/benefit/benefit-line.css';
import '../../../components/benefit/benefit.css';

import handMoney from './hand-money.svg';
import shield from './shield.svg';
import satisfaction from './satisfaction.svg';

export default function BenefitLine() {
  return (
    <div className="benefit-line">
      <div className="benefit-line__container container">
        <div className="benefit-line__item benefit benefit_light">
          <div className="benefit__image-block">
            <img className="benefit__image" src={handMoney} alt="Hand Money" />
          </div>
          <div className="benefit__text-block">
            <span className="benefit__text benefit__text_accent">
              Realistische
            </span>
            {' '}
            <span className="benefit__text">
              Preisindikation
            </span>
          </div>
        </div>

        <div className="benefit-line__separator"></div>

        <div className="benefit-line__item benefit benefit_light">
          <div className="benefit__image-block">
            <img className="benefit__image" src={shield} alt="Shield" />
          </div>
          <div className="benefit__text-block">
            <span className="benefit__text benefit__text_accent">
              Mehr Sicherheit
            </span>
            {' '}
            <span className="benefit__text">
              im Ankaufsprozess
            </span>
          </div>
        </div>

        <div className="benefit-line__separator"></div>

        <div className="benefit-line__item benefit benefit_light">
          <div className="benefit__image-block">
            <img className="benefit__image" src={satisfaction} alt="Satisfaction" />
          </div>
          <div className="benefit__text-block">
            <span className="benefit__text benefit__text_accent">
              Feedback
            </span>
            {' '}
            <span className="benefit__text">
              von Kollegen
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
