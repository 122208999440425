import StepId from '../data/step-id.enum';

export default function getBackVisible({ index, stepId }) {
  if (index === 0) {
    return false;
  }
  if (stepId === StepId.FINISH) {
    return false;
  }
  return true;
}
