import React from 'react';

import './privacy-policy.css';

export default function PrivacyPolicy() {
  return (
    <div className="privacy-policy hdlr1-article container">
      <h1>Datenschutz&shy;erklärung</h1>

      <h2>§ 1	Information über die Erhebung personenbezogener Daten</h2>

      <p>
        (1) Im Folgenden informieren wir über die Erhebung personenbezogener Daten bei Nutzung unserer Website. Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar sind, z. B. Name, Adresse, E-Mail-Adressen, Nutzerverhalten.
      </p>
      <p>
        (2) Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung 
        (DS-GVO) ist [Rheinlandmaschinen UG, Scheibenstraße 49, 40476 Düsseldorf, 
        info@haendler1.de] (<a href="/imprint">siehe unser Impressum</a>). 
        Unseren Datenschutzbeauftragten erreichen Sie unter info@haendler1.de 
        oder unserer Postadresse mit dem Zusatz „der Datenschutzbeauftragte“.
      </p>
      <p>
        (3) Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über ein Kontaktformular werden die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns gespeichert, um Ihre Fragen zu beantworten. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.
      </p>
      <p>
        (4) Falls wir für einzelne Funktionen unseres Angebots auf beauftragte Dienstleister zurückgreifen oder Ihre Daten für werbliche Zwecke nutzen möchten, werden wir Sie untenstehend im Detail über die jeweiligen Vorgänge informieren. Dabei nennen wir auch die festgelegten Kriterien der Speicherdauer.
      </p>

      <h2>§ 2	Ihre Rechte</h2>

      <p>
        (1) Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:
      </p>
      <ul>
        <li>Recht auf Auskunft,</li>
        <li>Recht auf Berichtigung oder Löschung,</li>
        <li>Recht auf Einschränkung der Verarbeitung,</li>
        <li>Recht auf Widerspruch gegen die Verarbeitung,</li>
        <li>Recht auf Datenübertragbarkeit.</li>
      </ul>
      <p>
        (2) Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschweren.
      </p>

      <h2>§ 3	Erhebung personenbezogener Daten bei Besuch unserer Website</h2>

      <p>
        (1) Bei der bloß informatorischen Nutzung der Website, also wenn Sie sich nicht registrieren oder uns anderweitig Informationen übermitteln, erheben wir nur die personenbezogenen Daten, die Ihr Browser an unseren Server übermittelt. Wenn Sie unsere Website betrachten möchten, erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und die Stabilität und Sicherheit zu gewährleisten (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO):
      </p>
      <ul>
        <li>IP-Adresse</li>
        <li>Datum und Uhrzeit der Anfrage</li>
        <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
        <li>Inhalt der Anforderung (konkrete Seite)</li>
        <li>Zugriffsstatus/HTTP-Statuscode</li>
        <li>jeweils übertragene Datenmenge</li>
        <li>Website, von der die Anforderung kommt</li>
        <li>Browser</li>
        <li>Betriebssystem und dessen Oberfläche</li>
        <li>Sprache und Version der Browsersoftware.</li>
      </ul>
      <p>
        (2) Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung unserer Website Cookies auf Ihrem Rechner gespeichert. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen verwendeten Browser zugeordnet gespeichert werden und durch welche der Stelle, die den Cookie setzt (hier durch uns), bestimmte Informationen zufließen. Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und effektiver zu machen.
      </p>
      <p>(3) Einsatz von Cookies:</p>
      <ol type="a">
        <li>
          <span>
            Diese Website nutzt folgende Arten von Cookies, deren Umfang und Funktionsweise im Folgenden erläutert werden:
          </span>
          <ul>
            <li>Transiente Cookies (dazu b),</li>
            <li>Persistente Cookies (dazu c).</li>
          </ul>
        </li>
        <li>
          Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser schließen. Dazu zählen insbesondere die Session-Cookies. Diese speichern eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere Website zurückkehren. Die Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder den Browser schließen.
        </li>
        <li>
          Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Sie können die Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit löschen.
        </li>
        <li>
          Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und z. B. die Annahme von Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie eventuell nicht alle Funktionen dieser Website nutzen können.
        </li>
        <li style={{ color: '#1e90ff' }}>
          [Wir setzen Cookies ein, um Sie für Folgebesuche identifizieren zu können, falls Sie über einen Account bei uns verfügen. Andernfalls müssten Sie sich für jeden Besuch erneut einloggen.]
        </li>
        <li style={{ color: '#1e90ff' }}>
          [Die genutzten Flash-Cookies werden nicht durch Ihren Browser erfasst, sondern durch Ihr Flash-Plug-in. Weiterhin nutzen wir HTML5 storage objects, die auf Ihrem Endgerät abgelegt werden. Diese Objekte speichern die erforderlichen Daten unabhängig von Ihrem verwendeten Browser und haben kein automatisches Ablaufdatum. Wenn Sie keine Verarbeitung der Flash-Cookies wünschen, müssen Sie ein entsprechendes Add-On installieren oder das Adobe-Flash-Killer-Cookie für Google Chrome. Die Nutzung von HTML5 storage objects können Sie verhindern, indem Sie in Ihrem Browser den privaten Modus einsetzen. Zudem empfehlen wir, regelmäßig Ihre Cookies und den Browser-Verlauf manuell zu löschen.]
        </li>
      </ol>

      <h2>§ 4	Weitere Funktionen und Angebote unserer Website</h2>

      <p>
        (1) Neben der rein informatorischen Nutzung unserer Website bieten wir verschiedene Leistungen an, die Sie bei Interesse nutzen können. Dazu müssen Sie in der Regel weitere personenbezogene Daten angeben, die wir zur Erbringung der jeweiligen Leistung nutzen und für die die zuvor genannten Grundsätze zur Datenverarbeitung gelten.
      </p>
      <p>
        (2) Teilweise bedienen wir uns zur Verarbeitung Ihrer Daten externer Dienstleister. Diese wurden von uns sorgfältig ausgewählt und beauftragt, sind an unsere Weisungen gebunden und werden regelmäßig kontrolliert.
      </p>
      <p>
        (3) Weiterhin können wir Ihre personenbezogenen Daten an Dritte weitergeben, wenn Aktionsteilnahmen, Gewinnspiele, Vertragsabschlüsse oder ähnliche Leistungen von uns gemeinsam mit Partnern angeboten werden. Nähere Informationen hierzu erhalten Sie bei Angabe Ihrer personenbezogenen Daten oder untenstehend in der Beschreibung des Angebotes.
      </p>
      <p>
        (4) Soweit unsere Dienstleister oder Partner ihren Sitz in einem Staat außerhalb des Europäischen Wirtschaftsraumen (EWR) haben, informieren wir Sie über die Folgen dieses Umstands in der Beschreibung des Angebotes.
      </p>

      <h2>§ 5	Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten</h2>

      <p>
        (1) Falls Sie eine Einwilligung zur Verarbeitung Ihrer Daten erteilt haben, können Sie diese jederzeit widerrufen. Ein solcher Widerruf beeinflusst die Zulässigkeit der Verarbeitung Ihrer personenbezogenen Daten, nachdem Sie ihn gegenüber uns ausgesprochen haben.
      </p>
      <p>
        (2) Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die Interessenabwägung stützen, können Sie Widerspruch gegen die Verarbeitung einlegen. Dies ist der Fall, wenn die Verarbeitung insbesondere nicht zur Erfüllung eines Vertrags mit Ihnen erforderlich ist, was von uns jeweils bei der nachfolgenden Beschreibung der Funktionen dargestellt wird. Bei Ausübung eines solchen Widerspruchs bitten wir um Darlegung der Gründe, weshalb wir Ihre personenbezogenen Daten nicht wie von uns durchgeführt verarbeiten sollten. Im Falle Ihres begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen.
      </p>
      <p>
        (3) Selbstverständlich können Sie der Verarbeitung Ihrer personenbezogenen 
        Daten für Zwecke der Werbung und Datenanalyse jederzeit widersprechen. Über 
        Ihren Werbewiderspruch können Sie uns unter folgenden Kontaktdaten 
        informieren: [<a href="/imprint">siehe unser Impressum</a>].
      </p>

      <h2>§ 6	Web Analytics</h2>

      <h3>1. Einsatz von Google Analytics</h3>

      <p>
        (1)	 Diese Website benutzt Google Analytics, einen Webanalysedienst der Google LLC („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Website, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Website-Aktivitäten zusammenzustellen und um weitere mit der Website-Nutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Website-Betreiber zu erbringen.
      </p>
      <p>
        (2)	 Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
      </p>
      <p>
        (3)	 Sie können die Speicherung der Cookies durch eine entsprechende Einstellung 
        Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in 
        diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website 
        vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der 
        durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten 
        (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch 
        Google verhindern, indem sie das unter dem folgenden Link verfügbare 
        Browser-Plug-in herunterladen und 
        installieren: <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
          https://tools.google.com/dlpage/gaoptout?hl=de
        </a>.
      </p>
      <p>
        (4)	 Diese Website verwendet Google Analytics mit der Erweiterung „_anonymizeIp()“. Dadurch werden IP-Adressen gekürzt weiterverarbeitet, eine Personenbeziehbarkeit kann damit ausgeschlossen werden. Soweit den über Sie erhobenen Daten ein Personenbezug zukommt, wird dieser also sofort ausgeschlossen und die personenbezogenen Daten damit umgehend gelöscht.
      </p>
      <p>
        (5)	 Wir nutzen Google Analytics, um die Nutzung unserer Website analysieren 
        und regelmäßig verbessern zu können. Über die gewonnenen Statistiken können 
        wir unser Angebot verbessern und für Sie als Nutzer interessanter ausgestalten. 
        Für die Ausnahmefälle, in denen personenbezogene Daten in die USA übertragen 
        werden, hat sich Google dem EU-US Privacy Shield 
        unterworfen, <a href="https://www.privacyshield.gov/EU-US-Framework">
          https://www.privacyshield.gov/EU-US-Framework
        </a>. Rechtsgrundlage für die Nutzung von Google Analytics ist 
        Art. 6 Abs. 1 S. 1 lit. f DS-GVO.
      </p>
      <p>
        (6)	 Informationen des Drittanbieters: Google Dublin, Google Ireland Ltd., 
        Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001. 
        Nutzerbedingungen: <a href="https://www.google.com/analytics/terms/de.html">
          https://www.google.com/analytics/terms/de.html
        </a>, Übersicht zum 
        Datenschutz: <a href="https://www.google.com/intl/de/analytics/learn/privacy.html">
          https://www.google.com/intl/de/analytics/learn/privacy.html
        </a>, sowie die 
        Datenschutzerklärung: <a href="https://www.google.de/intl/de/policies/privacy">
          https://www.google.de/intl/de/policies/privacy
        </a>.
      </p>

      <h3>2. Einsatz von Hotjar</h3>

      <p>
        (1)	 Diese Website benutzt Hotjar, einen Webanalysedienst der Hotjar Ltd. Hotjar verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Hotjar in Irland übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Website, wird Ihre IP-Adresse von Hotjar jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Im Auftrag des Betreibers dieser Website wird Hotjar diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Website-Aktivitäten zusammenzustellen und um weitere mit der Website-Nutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Website-Betreiber zu erbringen.
      </p>
      <p>
        (2)	 Sie können unter <a href="https://www.hotjar.com/legal/compliance/opt-out">
          https://www.hotjar.com/legal/compliance/opt-out
        </a> die Erhebung Ihrer Daten bei dem Besuch unserer Website jederzeit deaktivieren.
      </p>
      <p>
        (3)	 Wir nutzen Hotjar, um die Nutzung unserer Website analysieren und regelmäßig verbessern zu können. Über die gewonnenen Statistiken können wir unser Angebot verbessern und für Sie als Nutzer interessanter ausgestalten. Rechtsgrundlage für die Nutzung von Hotjar ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO.
      </p>
      <p>
        (4)	 Informationen des Drittanbieters: Hotjar Ltd., ST. Julian´s Business Centre, 
        3, Elia Zammit Street, St. Julian´s STJ 1000, Malta, Fax: +353 (1) 436 1001. 
        Nutzerbedingungen: <a href="https://www.hotjar.com/legal/policies/terms-of-service">
          https://www.hotjar.com/legal/policies/terms-of-service
        </a>, Datenschutzerklärung: <a href="https://www.hotjar.com/legal/policies/privacy">
          https://www.hotjar.com/legal/policies/privacy
        </a>.
      </p>

      <h2>§ 7	Social Media</h2>

      <h3>1. Einsatz von Social-Media-Plug-ins</h3>

      <p>
        (1)	 Wir setzen derzeit folgende Social-Media-Plug-ins ein: [Facebook]. Wir nutzen dabei die sog. Zwei-Klick-Lösung. Das heißt, wenn Sie unsere Seite besuchen, werden zunächst grundsätzlich keine personenbezogenen Daten an die Anbieter der Plug-ins weitergegeben. Den Anbieter des Plug-ins erkennen Sie über die Markierung auf dem Kasten über seinen Anfangsbuchstaben oder das Logo. Wir eröffnen Ihnen die Möglichkeit, über den Button direkt mit dem Anbieter des Plug-ins zu kommunizieren. Nur wenn Sie auf das markierte Feld klicken und es dadurch aktivieren, erhält der Plug-in-Anbieter die Information, dass Sie die entsprechende Website unseres Online-Angebots aufgerufen haben. Zudem werden die unter § 3 dieser Erklärung genannten Daten übermittelt. Im Fall von Facebook und Xing wird nach Angaben der jeweiligen Anbieter in Deutschland die IP-Adresse sofort nach Erhebung anonymisiert. Durch die Aktivierung des Plug-ins werden also personenbezogene Daten von Ihnen an den jeweiligen Plug-in-Anbieter übermittelt und dort (bei US-amerikanischen Anbietern in den USA) gespeichert. Da der Plug-in-Anbieter die Datenerhebung insbesondere über Cookies vornimmt, empfehlen wir Ihnen, vor dem Klick auf den ausgegrauten Kasten über die Sicherheitseinstellungen Ihres Browsers alle Cookies zu löschen.
      </p>
      <p>
        (2)	 Wir haben weder Einfluss auf die erhobenen Daten und Datenverarbeitungsvorgänge, noch sind uns der volle Umfang der Datenerhebung, die Zwecke der Verarbeitung, die Speicherfristen bekannt. Auch zur Löschung der erhobenen Daten durch den Plug-in-Anbieter liegen uns keine Informationen vor.
      </p>
      <p>
        (3)	 Der Plug-in-Anbieter speichert die über Sie erhobenen Daten als Nutzungsprofile und nutzt diese für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche Auswertung erfolgt insbesondere (auch für nicht eingeloggte Nutzer) zur Darstellung von bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren. Ihnen steht ein Widerspruchsrecht gegen die Bildung dieser Nutzerprofile zu, wobei Sie sich zur Ausübung dessen an den jeweiligen Plug-in-Anbieter wenden müssen. Über die Plug-ins bietet wir Ihnen die Möglichkeit, mit den sozialen Netzwerken und anderen Nutzern zu interagieren, so dass wir unser Angebot verbessern und für Sie als Nutzer interessanter ausgestalten können. Rechtsgrundlage für die Nutzung der Plug-ins ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO.
      </p>
      <p>
        (4)	 Die Datenweitergabe erfolgt unabhängig davon, ob Sie ein Konto bei dem Plug-in-Anbieter besitzen und dort eingeloggt sind. Wenn Sie bei dem Plug-in-Anbieter eingeloggt sind, werden Ihre bei uns erhobenen Daten direkt Ihrem beim Plug-in-Anbieter bestehenden Konto zugeordnet. Wenn Sie den aktivierten Button betätigen und z. B. die Seite verlinken, speichert der Plug-in-Anbieter auch diese Information in Ihrem Nutzerkonto und teilt sie Ihren Kontakten öffentlich mit. Wir empfehlen Ihnen, sich nach Nutzung eines sozialen Netzwerks regelmäßig auszuloggen, insbesondere jedoch vor Aktivierung des Buttons, da Sie so eine Zuordnung zu Ihrem Profil bei dem Plug-in-Anbieter vermeiden können.
      </p>
      <p>
        (5)	 Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch den Plug-in-Anbieter erhalten Sie in den im Folgenden mitgeteilten Datenschutzerklärungen dieser Anbieter. Dort erhalten Sie auch weitere Informationen zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre.
      </p>
      <p>
        (6)	 Adressen der jeweiligen Plug-in-Anbieter und URL mit deren Datenschutzhinweisen:
      </p>
      <ol type="a">
        <li>
          Facebook Inc., 1601 S California Ave, Palo Alto, California 94304, 
          USA; <a href="https://www.facebook.com/policy.php">
            https://www.facebook.com/policy.php
          </a>; weitere Informationen zur 
          Datenerhebung: <a href="https://www.facebook.com/help/186325668085084">
            https://www.facebook.com/help/186325668085084
          </a>. Facebook hat sich dem EU-US-Privacy-Shield 
          unterworfen, <a href="https://www.privacyshield.gov/EU-US-Framework">
            https://www.privacyshield.gov/EU-US-Framework
          </a>.
        </li>
      </ol>

      <h3>2. Einbindung von YouTube-Videos</h3>

      <p>
        (1)	 Wir haben YouTube-Videos in unser Online-Angebot eingebunden, die 
        auf <a href="https://www.YouTube.com">https://www.YouTube.com</a> gespeichert 
        sind und von unserer Website aus direkt abspielbar sind. Diese sind alle 
        im „erweiterten Datenschutz-Modus“ eingebunden, d. h. dass keine Daten über 
        Sie als Nutzer an YouTube übertragen werden, wenn Sie die Videos nicht 
        abspielen. Erst wenn Sie die Videos abspielen, werden die in Absatz 2 genannten 
        Daten übertragen. Auf diese Datenübertragung haben wir keinen Einfluss.
      </p>
      <p>
        (2)	 Durch den Besuch auf der Website erhält YouTube die Information, dass Sie die entsprechende Unterseite unserer Website aufgerufen haben. Zudem werden die unter § 3 dieser Erklärung genannten Daten übermittelt. Dies erfolgt unabhängig davon, ob YouTube ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder ob kein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil bei YouTube nicht wünschen, müssen Sie sich vor Aktivierung des Buttons ausloggen. YouTube speichert Ihre Daten als Nutzungsprofile und nutzt sie für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche Auswertung erfolgt insbesondere (selbst für nicht eingeloggte Nutzer) zur Erbringung von bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an YouTube richten müssen.
      </p>
      <p>
        (3)	 Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer 
        Verarbeitung durch YouTube erhalten Sie in der Datenschutzerklärung. Dort 
        erhalten Sie auch weitere Informationen zu Ihren Rechten und 
        Einstellungsmöglichkeiten zum Schutze Ihrer 
        Privatsphäre: <a href="https://www.google.de/intl/de/policies/privacy">
          https://www.google.de/intl/de/policies/privacy
        </a>. Google verarbeitet Ihre personenbezogenen Daten auch in den USA und 
        hat sich dem EU-US-Privacy-Shield 
        unterworfen, <a href="https://www.privacyshield.gov/EU-US-Framework">
          https://www.privacyshield.gov/EU-US-Framework
        </a>.
      </p>

      <h2>§ 8	Online-Werbung</h2>

      <h3>1. Remarketing</h3>

      <p>
        Neben Adwords Conversion nutzen wir die Anwendung Google Remarketing. Hierbei handelt es sich um ein Verfahren, mit dem wir Sie erneut ansprechen möchten. Durch diese Anwendung können Ihnen nach Besuch unserer Website bei Ihrer weiteren Internetnutzung unsere Werbeanzeigen eingeblendet werden. Dies erfolgt mittels in Ihrem Browser gespeicherter Cookies, über die Ihr Nutzungsverhalten bei Besuch verschiedener Websites durch Google erfasst und ausgewertet wird. So kann von Google Ihr vorheriger Besuch unserer Website festgestellt werden. Eine Zusammenführung der im Rahmen des Remarketings erhobenen Daten mit Ihren personenbezogenen Daten, die ggf. von Google gespeichert werden, findet durch Google laut eigenen Aussagen nicht statt. Insbesondere wird laut Google beim Remarketing eine Pseudonymisierung eingesetzt.
      </p>

      <h3>2. Facebook Custom Audiences</h3>

      <p>
        (1) Weiterhin verwendet die Website die Remarketing-Funktion „Custom Audiences“ der Facebook Inc. („Facebook“). Dadurch können Nutzern der Website im Rahmen des Besuchs des sozialen Netzwerkes Facebook oder anderer das Verfahren ebenfalls nutzende Websites interessenbezogene Werbeanzeigen („Facebook-Ads“) dargestellt werden. Wir verfolgen damit das Interesse, Ihnen Werbung anzuzeigen, die für Sie von Interesse ist, um unsere Website für Sie interessanter zu gestalten.
      </p>
      <p>
        (2) Aufgrund der eingesetzten Marketing-Tools baut Ihr Browser automatisch eine direkte Verbindung mit dem Server von Facebook auf. Wir haben keinen Einfluss auf den Umfang und die weitere Verwendung der Daten, die durch den Einsatz dieses Tools durch Facebook erhoben werden und informieren Sie daher entsprechend unserem Kenntnisstand: Durch die Einbindung von Facebook Custom Audiences erhält Facebook die Information, dass Sie die entsprechende Webseite unseres Internetauftritts aufgerufen haben, oder eine Anzeige von uns angeklickt haben. Sofern Sie bei einem Dienst von Facebook registriert sind, kann Facebook den Besuch Ihrem Account zuordnen. Selbst wenn Sie nicht bei Facebook registriert sind bzw. sich nicht eingeloggt haben, besteht die Möglichkeit, dass der Anbieter Ihre IP-Adresse und weitere Identifizierungsmerkmale in Erfahrung bringt und speichert.
      </p>
      <p>
        (3) Die Deaktivierung der Funktion „Facebook Custom Audiences“ ist [hier und] 
        für eingeloggte Nutzer 
        unter <a href="https://www.facebook.com/settings/?tab=ads#_möglich">
          https://www.facebook.com/settings/?tab=ads#_möglich
        </a>.
      </p>
      <p>
        (4) Rechtsgrundlage für die Verarbeitung Ihrer Daten ist Art. 6 Abs. 1 S. 1 lit. f 
        DS-GVO. Weitere Informationen zur Datenverarbeitung durch Facebook erhalten Sie 
        unter <a href="https://www.facebook.com/about/privacy">
          https://www.facebook.com/about/privacy
        </a>.
      </p>

      <h2>§ 9	Datensicherheit</h2>

      <p>
        Wir setzen zudem technische und organisatorische Sicherheitsmaßnahmen ein, um anfallende oder erhobene personenbezogene Daten zu schützen, insbesondere gegen zufällige oder vorsätzlich Manipulation, Verlust, Zerstörung oder gegen den Angriff unberechtigter Personen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.
      </p>

      <h2>§ 10	Keine Weitergabe personenbezogener Daten</h2>

      <p>
        Wir geben Ihre personenbezogenen Daten nicht an Dritte weiter, es sei denn, Sie haben in die Datenweitergabe eingewilligt oder wir sind aufgrund gesetzlicher Bestimmungen und/oder behördlicher oder gerichtlicher Anordnungen zu einer Datenweitergabe berechtigt oder verpflichtet. Dabei kann es sich insbesondere um die Auskunftserteilung für Zwecke der Strafverfolgung, zur Gefahrenabwehr oder zur Durchsetzung geistiger Eigentumsrechte handeln.
      </p>

      <h2>§ 11	Datenschutz und Websites Dritter</h2>

      <p>
        Die Website kann Hyperlinks zu und von Websites Dritter enthalten. Wenn Sie einem Hyperlink zu einer dieser Websites folgen, beachten Sie bitte, dass wir keine Verantwortung oder Gewähr für fremde Inhalte oder Datenschutzbebedingungen übernehmen können. Bitte vergewissern Sie sich der jeweils geltenden Datenschutzbedingungen, bevor Sie personenbezogene Daten an diese Websites übermitteln.
      </p>

      <h2>§ 12	Änderungen dieser Datenschutzbestimmungen</h2>

      <p>
        Wir behalten uns das Recht vor, diese Datenschutzbestimmungen jederzeit mit Wirkung für die Zukunft zu ändern. Eine jeweils aktuelle Version ist auf der Website verfügbar. Bitte suchen Sie die Website regelmäßig auf und informieren Sie sich über die geltenden Datenschutzbestimmungen.
      </p>

      <div className="privacy-policy__separator"></div>

      <p><b>September 2019</b></p>
    </div>
  );
}
