import React from 'react';
import { Link } from '@reach/router';


export default function NotFound() {
  return (
    <div className="hdlr1-article">
      <h1>404 Not Found</h1>

      <p>
        Die angeforderte Seite existiert nicht.
      </p>

      <p>
        <Link to="/dashboard">
          Zur Startseite
        </Link>
      </p>
    </div>
  );
}
