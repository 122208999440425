import React from 'react';
import { Slider } from 'antd';

import './filters.css';
import Select from '../../../components/select';

import cat1 from './images/cat-1.svg';
import cat2 from './images/cat-2.svg';
import cat3 from './images/cat-3.svg';
import cat4 from './images/cat-4.svg';
import cat5 from './images/cat-5.svg';
import cat6 from './images/cat-6.svg';


export default function Filters({ onControlMouseDown }) {
  function handleGeneralMouseDown(e) {
    e.preventDefault();
    onControlMouseDown();
  }

  return (
    <div>
      <h3 className="filters__heading">Kategorie</h3>
      <div style={{ marginBottom: '2rem' }}>
        <button
          className="filters__category-button"
          onMouseDown={handleGeneralMouseDown}
        >
          <img
            className="filters__category-image"
            src={cat1}
            alt="Category 1"
          />
        </button>
        <button
          className="filters__category-button"
          onMouseDown={handleGeneralMouseDown}
        >
          <img
            className="filters__category-image"
            src={cat2}
            alt="Category 2"
          />
        </button>

        <button
          className="filters__category-button"
          onMouseDown={handleGeneralMouseDown}
        >
          <img
            className="filters__category-image"
            src={cat3}
            alt="Category 3"
          />
        </button>
        <button
          className="filters__category-button"
          onMouseDown={handleGeneralMouseDown}
        >
          <img
            className="filters__category-image"
            src={cat4}
            alt="Category 4"
          />
        </button>

        <button
          className="filters__category-button"
          onMouseDown={handleGeneralMouseDown}
        >
          <img
            className="filters__category-image"
            src={cat5}
            alt="Category 5"
          />
        </button>
        <button
          className="filters__category-button"
          onMouseDown={handleGeneralMouseDown}
        >
          <img
            className="filters__category-image"
            src={cat6}
            alt="Category 6"
          />
        </button>
      </div>

      <h3 className="filters__heading">Typ</h3>
      <div style={{ marginBottom: '2rem' }}>
        <div>
          <label className="filters__select-label">Reihenweite</label>
          <Select
            fullSize
            onMouseDown={handleGeneralMouseDown}
          ></Select>
        </div>
        <div>
          <label className="filters__select-label">Aufnahmeweite</label>
          <Select
            fullSize
            onMouseDown={handleGeneralMouseDown}
          ></Select>
        </div>
        <div>
          <label className="filters__select-label">Modelbezeichnung</label>
          <Select
            fullSize
            onMouseDown={handleGeneralMouseDown}
          ></Select>
        </div>
        <div>
          <label className="filters__select-label">Bodenart</label>
          <Select
            fullSize
            onMouseDown={handleGeneralMouseDown}
          ></Select>
        </div>
      </div>

      <h3 className="filters__heading" style={{ marginBottom: 0 }}>Preis</h3>
      <div className="filters__slider-block" style={{ marginBottom: '1rem' }}>
        <div className="filters__slider-edge">0€</div>
        <div className="filters__slider-wrapper">
          <Slider
            defaultValue={100000}
            max={700000}
            step={100}
            tipFormatter={null}
            onChange={ e => onControlMouseDown() }
          />
        </div>
        <div className="filters__slider-edge">700.000€</div>
      </div>

      <h3 className="filters__heading" style={{ marginBottom: 0 }}>Baujahr</h3>
      <div className="filters__slider-block" style={{ marginBottom: '2rem' }}>
        <div className="filters__slider-edge">1990</div>
        <div className="filters__slider-wrapper">
          <Slider
            range
            min={1990}
            max={2019}
            tipFormatter={null}
            defaultValue={[1993, 2009]}
            onChange={ e => onControlMouseDown() } 
          />
        </div>
        <div className="filters__slider-edge">2019</div>
      </div>

      <h3
        className="filters__heading filters__heading_disabled"
        style={{ marginBottom: 0 }}
      >
        Standort eingeben
      </h3>
      <div
        className="filters__slider-block filters__slider-block_disabled"
        style={{ marginBottom: '1rem' }}
      >
        <div className="filters__slider-edge">0 km</div>
        <div className="filters__slider-wrapper">
          <Slider
            max={500}
            defaultValue={300}
            tipFormatter={null}
            disabled
          />
        </div>
        <div className="filters__slider-edge">> 500 km</div>
      </div>
    </div>
  );
}
